import React, { useState } from "react";
  
export const Context = React.createContext();
export const ContextProvider = ({ children }) => {
    const [form, setForm] = useState('signup');
    // const [sform, sSetForm] = useState('hello');
    const [modal, setModal] = useState(false);
    const [reminder, setReminder] = useState('');
  
    return (
        <Context.Provider value={{ form, setForm, modal, setModal ,reminder, setReminder}}>
            {children}
        </Context.Provider>
    );
};