import { toast } from "react-toastify";


export const SuccessNotification = () => {
    toast.success("Logged in Successfully 👍", {
        position:   toast.POSITION.TOP_CENTER,
        autoClose: 2500,
        closeOnClick: true,
        theme: "colored"
    });
}

export const SuccessScheduleTrainerNotification = () => {
    toast.success("Successfully Scheduled your slot👍", {
        position:   toast.POSITION.TOP_CENTER,
        autoClose: 2500,
        closeOnClick: true,
        theme: "colored"
    });
}

export const FailureScheduleTrainerNotification = () => {
    toast.error("Error while scheduling your slot!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2500,
        closeOnClick: true,
        theme: "colored"
    });
}
export const SuccessBookedSlotNotification = () => {
    toast.success("Successfully Booked your slot👍", {
        position:   toast.POSITION.TOP_CENTER,
        autoClose: 2500,
        closeOnClick: true,
        theme: "colored"
    });
}
export const AlredyBookedSlotNotification = () => {
    toast.error("Already Booked selected slot, please book another slot😣", {
        position:   toast.POSITION.TOP_CENTER,
        autoClose: 2500,
        closeOnClick: true,
        theme: "colored"
    });
}
export const SuccessUploadedProfileNotification = () => {
    toast.success("Successfully added your Profile👍", {
        position:   toast.POSITION.TOP_CENTER,
        autoClose: 2500,
        closeOnClick: true,
        theme: "colored"
    });
}

export const SuccessDeleteScheduleNotification = () => {
    toast.success("Successfully deleted your scheduling👍", {
        position:   toast.POSITION.TOP_CENTER,
        autoClose: 2500,
        closeOnClick: true,
        theme: "colored"
    });
}

export const SuccessUpdatedProfileNotification = () => {
    toast.success("Successfully Updated your Profile👍", {
        position:   toast.POSITION.TOP_CENTER,
        autoClose: 2500,
        closeOnClick: true,
        theme: "colored"
    });
}

export const SuccessConfirmScheduleNotification = () => {
    toast.success("Successfully Confirmed your Schedule👍!", {
        position:   toast.POSITION.TOP_CENTER,
        autoClose: 2500,
        closeOnClick: true,
        theme: "colored"
    });
}

export const SuccessCancelScheduleNotification = () => {
    toast.success("Successfully Cancelled your Schedule👍", {
        position:   toast.POSITION.TOP_CENTER,
        autoClose: 2500,
        closeOnClick: true,
        theme: "colored"
    });
}

export const SuccessCancelTraineeScheduleNotification = () => {
    toast.success("Successfully Cancelled your Booking👍", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2500,
        closeOnClick: true,
        theme: "colored"
    });
}

export const ErrorNotification = () => {
    toast.error("Error 😣", {
        position:  toast.POSITION.TOP_CENTER,
        autoClose: 2500,
        theme: "colored",
        closeOnClick: true,
    });
}
export const InfoNotification = () => {
    toast.info("User Logged out👍", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2500,
        theme: "colored"
    });
}

export const SessionTimeoutInfoNotification = () => {
    toast.error("Your session is expired👍", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2500,
        theme: "colored"
    });
}
export const SuccessUpdatePasswordNotification = () => {
    toast.success("Successfully Updated your Password", {
        position:   toast.POSITION.TOP_CENTER,
        autoClose: 2500,
        closeOnClick: true,
        theme: "colored"
    });
}
export const FailureUpdatePasswordNotification = () => {
    toast.error("Invalid old password!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2500,
        closeOnClick: true,
        theme: "colored"
    });
}