import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import store from "./pages/features/store";
import { ContextProvider } from "./pages/features/storeContext";
import "./index.css";
import { ConfigProvider } from "antd";

render(
  <ContextProvider>
    <Provider store={store}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#113293",
          },
        }}
      >
        <App>{/* <Routes /> */}</App>
      </ConfigProvider>
    </Provider>
  </ContextProvider>,
  document.getElementById("root")
);
