import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { Context } from "../../pages/features/storeContext";
import { InfoNotification } from "../../pages/notifications/notification";
import "./sidenav.scss";
import { Layout, Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../../pages/features/selector";
import { getUser } from "../../pages/features/userSlice";
import logo from "../../assets/images/dummy_logo.png";

const { Sider, Content } = Layout;
const trainerItems = [
  { label: "Home", value: "Home", key: "1" },
  {
    label: "Schedule Inventory",
    value: "book-session-availability",
    key: "2",
  },
  { label: "Booked Sessions", value: "my-schedules", key: "3" },
  { label: "Schedule Openings", value: "trainer-schedulesing", key: "7" },
  { label: "My Account", value: "my-account", key: "4" },
  { label: "Change Password", value: "manage-password", key: "5" },
  { label: "Logout", value: "logout", key: "6" }
];
const traineeItems = [
  { label: "Home", value: "Home", key: "1" },
  {
    label: "Schedule Training",
    value: "check-trainer-schedule",
    key: "2",
  },
  { label: "Booked Sessions", value: "trainee-schedules", key: "3" },
  { label: "My Account", value: "my-account", key: "4" },
  { label: "Change Password", value: "manage-password", key: "5" },
  { label: "Logout", value: "logout", key: "6" },
];
const Sidenav = ({ props, setIsLoggedin }) => {
  const [selectedKeys, setSelectedKeys] = useState(["1"]);
  const [items, setItems] = useState([]);
  const [role, setRole] = useState("");
  const navigate = useNavigate();
  const onLogOut = () => {
    navigate("/home");
    setIsLoggedin(false);
    localStorage.removeItem("userToken");
    localStorage.removeItem("urlPath");
    InfoNotification();
  };
  const location = useLocation();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => getCurrentUser(state));
  useEffect(() => {
    // console.log(localStorage)
    const token = localStorage.getItem("userToken");
    dispatch(getUser());
    if ((token && location.pathname == "/home") || location.pathname == "/") {
      navigate("/dashboard");
    }
    const userRole = localStorage.getItem("role");
    setRole(userRole);
    if (userRole == "Trainer") {
      setItems(trainerItems);
    } else if (userRole == "Trainee") {
      setItems(traineeItems);
    }
  }, []);
  useEffect(() => {
    if (
      location.pathname == "/trainer-scheduling" ||
      location.pathname == "/check-trainer-schedule"
    ) {
      setSelectedKeys(["2"]);
    } else if (
      location.pathname == "/trainer-schedules" ||
      location.pathname == "/trainee-schedules"
    ) {
      setSelectedKeys(["3"]);
    } else if (location.pathname == "/profile") {
      setSelectedKeys(["4"]);
    } else if (location.pathname == "/manage-password") {
      setSelectedKeys(["5"]);
    }
  }, [location.pathname]);
  const onNavigate = (key) => {
    setSelectedKeys(key);
    if (role == "Trainer") {
      if (key == "1") {
        navigate("/dashboard");
      } else if (key == "2") {
        navigate("/trainer-scheduling");
      } else if (key == "3") {
        navigate("/trainer-schedules");
      } else if (key == "4") {
        navigate("/profile");
      } else if (key == "5") {
        navigate("/manage-password");
      } else if (key == "6") {
        onLogOut();
      }
      else if (key == "7") {
        navigate("/trainer-schedulesing");
      }
    } else {
      if (key == "1") {
        navigate("/dashboard");
      } else if (key == "2") {
        navigate("/check-trainer-schedule");
      } else if (key == "3") {
        navigate("/trainee-schedules");
      } else if (key == "4") {
        navigate("/profile");
      } else if (key == "5") {
        navigate("/manage-password");
      } else if (key == "6") {
        onLogOut();
      }
    }
  };
  // console.log(items);
  // console.log(selectedKeys);
  return (
    <Sider
      theme="light"
      style={{ position: "fixed", left: 0, top: 0, bottom: 0 }}
    >
      <div className="upload-img">
        {currentUser?.file_name ? (
          <img
            src={`http://netqwix.aitacs.com:5200/uploadedFile/${currentUser?.file_name}`}
            width={200}
            height={150}
          />
        ) : (
          <img src={logo} />
        )}
      </div>
      <Menu
        items={items}
        selectedKeys={selectedKeys}
        className="sidenav-menu"
        onClick={(e) => {
          setSelectedKeys([e.key]);
          onNavigate(e.key);
        }}
      />
    </Sider>
  );
};
export default Sidenav;
