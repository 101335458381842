import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  clearState,
  deleteScheduleById,
  getDetailsById,
  getScheduleDetailsById,
  getUser,
  userSelector,
} from "../../../pages/features/userSlice";
import { SuccessDeleteScheduleNotification } from "../../../pages/notifications/notification";
import Sidenav from "../../sidenav/sidenav";
import "./trainerSchedules.css";
import UpdateTrainerSchedule from "./updateTrainerSchedule";
import { Row, Col, Typography } from "antd";

const TrainerSchedules = () => {
  const {
    isFetching,
    isDeleteScheduleByIdSuccess,
    isError,
    errorMessage,
    isFetchUserSuccessResponse,
    TrainerSchedules,
  } = useSelector(userSelector);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showUpdate, setShowUpdate] = useState(false);
  const [currentUpdatingSchedule, setCurrentUpdatingSchedule] = useState(null);

  useEffect(() => {
    dispatch(getScheduleDetailsById());
    if (isDeleteScheduleByIdSuccess) {
      SuccessDeleteScheduleNotification();
      dispatch(clearState());
    }
  }, [isDeleteScheduleByIdSuccess]);

  const deleteSchedule = (value) => {
    dispatch(deleteScheduleById(value));
  };

  const dateFormat = (value) => {
    
    value = value.replace(/:[^:]*$/, "");
    console.log(value);

    value = value.replace(/^0+/, "");
    const hourMin = value.split(":");
    var hour = hourMin[0];
    var min = hourMin[1];

    var AmOrPm = hour >= 12 ? "pm" : "am";
    hour = hour % 12 || 12;
    //var minutes = dt.getMinutes() ;

    value = hour + ":" + min + "" + AmOrPm;

    console.log(value);

    // return value.replace(/^0+/, '')
    return value; 
    // return value.replace(/^(?:00:)?0?/, '').splice(:2);
    // return new Date().toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'});
  };

  return (
    <> <div>
    <section className="dashboard_info">
    <Row>
          <Col span={24}>
            <Typography.Title level={4} className="section-title">
            Current Schedule
            </Typography.Title>
      <Row>
        <Col span={24}>
          <div className="tab-content">
           
            <div className="search-result-item">
              {TrainerSchedules.data?.map((data) => (
                <Row style={{marginBottom: "10px"}}>
                  <Col span={14}>
                  
                      {data.day}, {dateFormat(data.start_time)} - 
                      {dateFormat(data.end_time)}
                  </Col>
                  <Col span={4}   style={{ marginRight: "10px" }}>
                    <button
                      className="btn btn-danger btn-info btn-sm"
                      onClick={() => deleteSchedule(data.id)}
                    
                    >
                      Delete
                    </button> </Col>  <Col span={4}>
                    <button
                      className="btn btn-primary btn-info btn-sm"
                      style={{color: "white"}}
                      onClick={() => {
                        setShowUpdate(true);
                        setCurrentUpdatingSchedule(data);
                      }}
                    >
                      Update
                    </button>
                  </Col>
                </Row>
              ))}
            </div>
          </div>
        </Col>
      </Row>
      {showUpdate && (
        <UpdateTrainerSchedule
          setShowUpdate={setShowUpdate}
          currentUpdatingSchedule={currentUpdatingSchedule}
        />
      )}
      </Col></Row>
      </section></div>
    </>
  );
};

export default TrainerSchedules;
