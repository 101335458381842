import ForgotPasswordComponent from "./ForgotPasswordComponent";
import ForgotPasswordOtp from "./ForgotPasswordOtp";
import { useState } from "react";
import "./header.scss";

const ForgotPassword = ({ setShowForgotPassword, setShowSignup,setShowResetPwd }) => {
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [email, setEmail] = useState();
  return (
    <>
      {showOtpForm ? (
        <ForgotPasswordOtp email={email} setShowOtpForm={setShowOtpForm} setShowForgotPassword={setShowForgotPassword}/>
      ) : (
        <ForgotPasswordComponent
          setShowForgotPassword={setShowForgotPassword}
          setEmail={setEmail}   setShowResetPwd={setShowResetPwd}
          setShowSignup={setShowSignup} 
          setShowOtpForm={setShowOtpForm}
        />
      )}
    </>
  );
};
export default ForgotPassword;
