import { Typography, Row, Col, Button, Avatar } from "antd";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  cancelTraineeBookedScheduledById,
  clearState,
  getTraineeScheduleDetailsById,
  userSelector,
} from "../../../pages/features/userSlice";
import {
  ErrorNotification,
  SuccessBookedSlotNotification,
  SuccessCancelTraineeScheduleNotification,
} from "../../../pages/notifications/notification";

const TraineeSchedules = () => {
  const {
    isSuccess,
    isFetchUserSuccessResponse,
    TraineeSchedulesResponse,
    isbookTraineeSlotSuccess,isSlotBookedAlready
  } = useSelector(userSelector);
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate();
  const dispatch = useDispatch();
  if (isbookTraineeSlotSuccess  && !isSlotBookedAlready) {
    SuccessBookedSlotNotification();
    dispatch(clearState());
  }
  useEffect(() => {
    dispatch(getTraineeScheduleDetailsById());
  }, []);

  if (isSuccess) {
    console.log("am in dashboard", TraineeSchedulesResponse);
  }
  const dateFormat = (value) => {
    value = value.replace(/:[^:]*$/, "");

    value = value.replace(/^0+/, "");
    const hourMin = value.split(":");
    var hour = hourMin[0];
    var min = hourMin[1];

    var AmOrPm = hour >= 12 ? " pm" : " am";
    hour = hour % 12 || 12;
    //var minutes = dt.getMinutes() ;

    value = hour + ":" + min + "" + AmOrPm;

   // console.log(value);

    // return value.replace(/^0+/, '')
    return value;
  }; 
  const dayFormat=(eventDate)=>
  { 
    const date1=  new moment(eventDate).format("MMM Do, YYYY");
    return date1;
  }
  const dayValueFormat=(eventDate)=>
  { 
    const date1=  new moment(eventDate).format("dddd");
    return date1;
  }
  const sessionDuration = (start_time,end_time) => {
   

    var scheduled_date=new Date();
    scheduled_date.setHours(parseInt(start_time.split(':')[0]));
    scheduled_date.setMinutes(parseInt(start_time.split(':')[1])); 
    var start = new moment(new Date(scheduled_date)).format("HH:mm ");

    scheduled_date.setHours(parseInt(end_time.split(':')[0]));
    scheduled_date.setMinutes(parseInt(end_time.split(':')[1]));
    var end =new moment(new Date(scheduled_date)).format("HH:mm ");
  
    var startTime = moment(start, "HH:mm");
    var endTime = moment(end, "HH:mm");
    var sessionDuration = endTime.diff(startTime, "minutes");
   // console.log('sessionDuration'+sessionDuration);
    return sessionDuration;
  };
  const bookedStatus = (value) => {
    if (value == "B") {
      return "Booked";
    } else if (value == "C") {
      return "Confirmed";
    } else if (value == "D") {
      return "Cancel";
    } else return "";

    // return value.replace(/^(?:00:)?0?/, '').splice(:2);
    // return new Date().toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'});
  };
  const onCancel = (id) => {
    setLoading(true)
    dispatch(
      cancelTraineeBookedScheduledById({
        id: id,
        onSuccess: () => {
          setLoading(false)
          SuccessCancelTraineeScheduleNotification();
          dispatch(getTraineeScheduleDetailsById());
        },
        onFailure: () => {
          console.log('failure')
          setLoading(false)
          ErrorNotification()
        },
      })
    );
  };
  const hrefValue = (value) =>{
    return "https://pocdemo.aitacs.com/"+value+"#userInfo.displayName="+isFetchUserSuccessResponse[0].full_name;
  }
  return (
    <div>
      <section className="dashboard_info">
        <Row>
          <Col span={24}>
            <Typography.Title level={4} className="section-title">
              Booked Sessions
            </Typography.Title>

            <div className="tab-content">
              <div id="home" className="container tab-pane active">
                {/* <div className="row">
									<div className="col-md-3"><img src={Image1} /></div>
									<div className="col-md-3"><img src={Image2} /></div>
									<div className="col-md-3"><img src={Image3} /></div>
									<div className="col-md-3"><img src={Image4} /></div>
									<div className="col-md-3"><img src={Image5} /></div>
									<div className="col-md-3"><img src={Image6} /></div>
									<div className="col-md-3"><img src={Image7} /></div>
									<div className="col-md-3"><img src={Image8} /></div>
									<div className="col-md-3"><img src={Image9} /></div>
									<div className="col-md-3"><img src={Image10} /></div>
									<div className="col-md-3"><img src={Image11} /></div>
									<div className="col-md-3"><img src={Image12} /></div>
								</div> */}
                {TraineeSchedulesResponse.data?.map((data) => (
                  <section className="search-result-item">
                    <Row className="d-flex align-items-center">
                      <Col  >
                        <img
                          className="image"
                          style={{ borderRadius: "30px",width: 100,
                          height: 100 }}
                          // src="https://bootdey.com/img/Content/avatar/avatar1.png"
                          src={`http://netqwix.aitacs.com:5200/uploadedFile/${data.file_name}`}
                        />
                      </Col> 
                      <Col  push={1}>
                        <h4 className="search-result-item-heading">
                          <Typography.Title level={5}>
                            {data.full_name}
                          </Typography.Title>
                        </h4>
                        <div className="d-flex flex-column">
                         
                          <small style={{ color: "gray" }}>
                          {dayValueFormat(data.scheduled_date)}, {dayFormat(data.scheduled_date)}{" "}
                            {dateFormat(data.start_time)} to{" "}
                            {dateFormat(data.end_time)}
                          </small>
                          <small style={{ color: "gray" }}></small>
                          <small style={{ color: "gray" }}>
                          {sessionDuration(data.start_time,data.end_time)} Minute Session 
                          </small>
                        </div> 
                        <Button
                          className="btn btn-primary btn-info"
                          style={{
                            cursor: "default",
                            color: "white",  margin:5
                          }}
                        >
                          {bookedStatus(data.status)}
                        </Button> 
                        { data.status==='C'?
                      data.meetingcode?<><a href={hrefValue(data.meetingcode)} target="_blank"  
                         className="btn btn-primary btn-info" 
                         style={{
                          cursor: "default",
                          color: "white",  margin:5
                        }}>Start </a></>:<></>
                           :<></>}
                            { data.status!=='D'?
                        <Button loading={loading}      style={{
                            cursor: "default",
                            color: "white",  margin:5
                          }}
                        onClick={() => onCancel(data.scheduled_id)} type="primary" danger>
                          Cancel
                        </Button>:<></>}
                      </Col>
                    </Row>
                  </section>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </section>
    </div>
  );
};

export default TraineeSchedules;
