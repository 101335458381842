import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

 //const SERVICE_URL = "http://localhost:5200";
const SERVICE_URL = "http://avondale.aitacs.com:5200"; 

const initialState = {
  signUpresponse: [],
  logInresponse: [],
  logInreminder: false,
  CheckTrainerSchedulingresponse: [],
  TrainerSchedules: [],
  TraineeBookedSchedules: [],
  TrainerScheduling: [],
  TraineeSchedulesResponse: [],
  getUserresponse: [],
  getAllTrainerNamesResponse: [],
  getSchedulerBySearchResponse: [],
  getSchedulerDetailsByIdResponse: [],
  getProfileDetailsResponse: [],
  isbookTraineeSlotSuccess: false,
  isSlotBookedAlready:false,
  updateTrainerProfileisSuccess: false,
  isSuccessuploadTrainerProfile: false,
  getOnlineUsersDataisSuccess: false,
  getOnlineUsersDataResponse:[],
  isFetching: false,
  isSuccess: false,
  isFetchingUser: false,
  isError: false,
  errorMessage: "",
  user: {},
};

export const signUpUser = createAsyncThunk(
  "users/register",
  async ({ values, onSuccess, onFailure }, thunkAPI) => {
    console.log("am here");
    try {
      const response = await fetch(SERVICE_URL + "/api/register", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
      
      let { data, userdata, message, reminder } = await response.json(); 
      if (response.status === 200) { 
        localStorage.setItem("userToken", data);
        localStorage.setItem("role", userdata.role);
        localStorage.setItem("email",userdata.email);
        localStorage.setItem("id",userdata.id);
      //  (setLoginUser({email:userdata.email,role:userdata.role,user_id:userdata.id,status:'online'}))
        onSuccess();
        return { data: data, userdata: userdata, message, reminder };
      } else {
        onFailure();
        return thunkAPI.rejectWithValue(message);
      }
    } catch (e) {
      onFailure();
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const loginUser = createAsyncThunk(
  "users/login",
  async ({ values, onSuccess, onFailure }, thunkAPI) => {
    try {
      const response = await fetch(SERVICE_URL + "/api/login", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
      let { data, userdata, message, reminder } = await response.json();

      if (response.status === 200) {
        localStorage.setItem("userToken", data);
        localStorage.setItem("role", userdata.role);  
        localStorage.setItem("email",userdata.email);
        localStorage.setItem("id",userdata.id);
    //    var logoutTimer =   setTimeout(function() { localStorage.clear(); }, ( 10 * 1000)); 
      //  console.log(logoutTimer);
   
        onSuccess();
        return { data: data, userdata: userdata, message, reminder };
      } else {
        onFailure();
     //   alert("Invalid User Or Password");
        return thunkAPI.rejectWithValue(message);
      }
    } catch (error) {
      onFailure();
   //   alert("Invalid User Or Password");
      thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const managePassword = createAsyncThunk(
  "users/login",
  async ({ values, onSuccess, onFailure }, thunkAPI) => {
    const jwtToken = localStorage.getItem("userToken");
    try {
      const response = await fetch(SERVICE_URL + "/api/managePassword", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(values),
      });
      let { data, userdata, message, reminder } = await response.json();

      if (response.status === 200) {
        onSuccess();
        return { data: data, userdata: userdata, message, reminder };
      } else {
        onFailure();
        return thunkAPI.rejectWithValue(message);
      }
    } catch (error) {
      onFailure();
      // alert("Invalid User Or Password222");
      thunkAPI.rejectWithValue(error.response);
    }
  }
);

// export const updateTrainerScheduler = createAsyncThunk(
//   "users/updateTrainerScheduler",
//   async ({ data, onSuccess, onFailure }, thunkAPI) => {
//     console.warn(data);
//     try {
//       const response = await fetch(SERVICE_URL + "/api/updateTrainerScheduler", {
//         method: "POST",
//         headers: {
//           Accept: "application/json",
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(data),
//       });
//       let { data, message, reminder } = await response.json();

//       if (response.status === 200) {
//         onSuccess();
//         // return { data: data, userdata: userdata, message, reminder };
//       } else {
//         onFailure();
//         // alert("Invalid User Or Password");
//         return thunkAPI.rejectWithValue(message);
//       }
//     } catch (error) {
//       onFailure();
//       // alert("Invalid User Or Password222");
//       thunkAPI.rejectWithValue(error.response);
//     }
//   }
// );

export const updateTrainerScheduler = createAsyncThunk(
  "users/updateTrainerScheduler",
  async ({ details, onSuccess, onFailure }, thunkAPI) => {
    const jwtToken = localStorage.getItem("userToken");
    try {
      const response = await fetch(
        SERVICE_URL + "/api/updateTrainerScheduler",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
          body: JSON.stringify(details),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        onSuccess();
        return { data: data };
      } else {
        onFailure();
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error) {
      onFailure();
      thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const verifyEmail = createAsyncThunk(
  "users/verifyEmail",
  async ({ email, onSuccess, onFailure }, thunkAPI) => {
    try {
      const response = await fetch(SERVICE_URL + "/api/verifyEmail", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email }),
      });
      let data = await response.json();
      if (response.status === 200) {
        onSuccess(data);
        return { data: data };
      } else {
        onFailure();
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error) {
      onFailure(error);
      thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const sendOtp = createAsyncThunk(
  "users/sendOtp",
  async ({ values, onSuccess, onFailure }, thunkAPI) => {
    try {
      const response = await fetch(SERVICE_URL + "/api/sendOtp", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
      console.log(await response);  
      console.log(response);

      if (response.status === 200) { 
        localStorage.setItem("otpMail", values.email);
        onSuccess();
        console.log('send otp success');
        console.log('Email...')
        console.log(values)
        console.log('...Email')
        return { values : 'message' };
      } else {
        onFailure();
        //localStorage.removeItem("otpMail", values);
        return thunkAPI.rejectWithValue('message');
      }
    } catch (e) { 
      console.log('userSlice catch');
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "users/changeForgotPwd",
  async ({ values, onSuccess, onFailure }, thunkAPI) => {
    try {
      const response = await fetch(SERVICE_URL + "/api/changeForgotPwd", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
        console.log(response,'qwqweqweqweqwe');
      let { data, userdata, message } = await response.json();

      if (response.status === 200) {
        localStorage.setItem("userToken", data);
        localStorage.setItem("role", userdata.role);  
        localStorage.setItem("email",userdata.email);
        localStorage.setItem("id",userdata.id);
        
        onSuccess();
        return { data: message };
      } else {
        onFailure();
        return thunkAPI.rejectWithValue(message);
      }
    } catch (e) {
      onFailure();
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getUser = createAsyncThunk("users/getUser", async (thunkAPI) => {
  const jwtToken = localStorage.getItem("userToken");
  try {
    const response = await fetch(SERVICE_URL + "/api/getUserData", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    });
    let data = await response.json();
    if (response.status === 200) {
      return { data: data, user: data };
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  } catch (error) {
    thunkAPI.rejectWithValue(error.response);
  }
});

export const scheduleTrainer = createAsyncThunk(
  "users/scheduleUser",
  async (params, thunkAPI) => {
    const jwtToken = localStorage.getItem("userToken");
    const { details, onSuccess, onFailure } = params;
    try {
      const response = await fetch(SERVICE_URL + "/api/scheduleUser", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          details,
        }),
      });
      if (response.status === 200) {
        let data = await response.json();
        onSuccess();
        return { data: data };
      } else {
        onFailure();
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error) {
      console.log(error);
      onFailure();
      thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const getScheduleDetailsById = createAsyncThunk(
  "users/getScheduleDetailsById",
  async (thunkAPI) => {
    const jwtToken = localStorage.getItem("userToken");
    try {
      const response = await fetch(
        SERVICE_URL + "/api/getScheduleDetailsById",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return { data: data };
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error) {
      thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const getScheduledDetailsByTrainerId = createAsyncThunk(
  "users/getScheduledDetailsByTrainerId",
  async (thunkAPI) => {
    const jwtToken = localStorage.getItem("userToken");
    try {
      const response = await fetch(
        SERVICE_URL + "/api/getScheduledDetailsByTrainerId",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return { data: data };
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error) {
      thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const deleteScheduleById = createAsyncThunk(
  "users/deleteScheduleById",
  async (value, thunkAPI) => {
    console.log("am in slice", value);
    const jwtToken = localStorage.getItem("userToken");
    try {
      const response = await fetch(SERVICE_URL + "/api/deleteScheduleById", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          value,
        }),
      });
      let data = await response.json();
      if (response.status === 200) {
        return { data: data };
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error) {
      thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const cancelScheduledById = createAsyncThunk(
  "users/cancelScheduledById",
  async (value, thunkAPI) => {
    console.log("am in slice", value);
    const jwtToken = localStorage.getItem("userToken");
    try {
      const response = await fetch(SERVICE_URL + "/api/cancelScheduledById", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          value,
        }),
      });
      let data = await response.json();
      if (response.status === 200) {
        return { data: data };
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error) {
      thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const cancelTraineeBookedScheduledById = createAsyncThunk(
  "users/cancelTraineeScheduledById",
  async (values, thunkAPI) => {
    const { id, onSuccess, onFailure } = values;
    const jwtToken = localStorage.getItem("userToken");
    try {
      const response = await fetch(
        SERVICE_URL + "/api/cancelTraineeScheduledById",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id,
          }),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        onSuccess();
        return { data: data };
      } else {
        onFailure();
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error) {
      onFailure();
      thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const confirmScheduledById = createAsyncThunk(
  "users/confirmScheduledById",
  async (value, thunkAPI) => {
    console.log("am in slice", value);
    const jwtToken = localStorage.getItem("userToken");
    try {
      const response = await fetch(SERVICE_URL + "/api/confirmScheduledById", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          value,
        }),
      });
      let data = await response.json();
      if (response.status === 200) {
        return { data: data };
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error) {
      thunkAPI.rejectWithValue(error.response);
    }
  }
);
export const getAllTrainerNames = createAsyncThunk(
  "users/getAllTrainerNames",
  async (thunkAPI) => {
    const jwtToken = localStorage.getItem("userToken");
    try {
      const response = await fetch(SERVICE_URL + "/api/getAllTrainerNames", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });
      let data = await response.json();
      if (response.status === 200) {
        return { data: data };
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error) {
      thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const getSchedulers = createAsyncThunk(
  "users/getSchedulers",
  async (thunkAPI) => {
    const jwtToken = localStorage.getItem("userToken");
    try {
      const response = await fetch(SERVICE_URL + "/api/getSchedulers", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });
      let data = await response.json();
      if (response.status === 200) {
        return { data: data };
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error) {
      thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const getSchedulerBySearch = createAsyncThunk(
  "users/getSchedulerBySearch",
  async (value, thunkAPI) => {
    console.log(value);
    var userArray = [];
    var dayArray = [];
    console.log(userArray); 
    value.set.forEach((element) => {
      userArray.push(parseInt(element.id));
    });    console.log(userArray); 
    console.log('dayArray'); 
    console.log(dayArray); 
    if(value.onDaySelect!==undefined)
    value.onDaySelect.forEach((element) => {
      dayArray.push("'" + element.name + "'");
    });
    console.log(dayArray); 
    value.userId = userArray;
    value.days = dayArray;
    console.log(userArray);   console.log(dayArray);
    const jwtToken = localStorage.getItem("userToken");
    try {
      const response = await fetch(SERVICE_URL + "/api/getSchedulerBySearch", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          value,
        }),
      });
      let data = await response.json();
      console.log('data'+data);
      if (response.status === 200) {
        console.log(data);
        return { data: data };
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error) {
      thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const getTraineeScheduleDetailsById = createAsyncThunk(
  "users/getTraineeScheduleDetailsById",
  async (thunkAPI) => {
    const jwtToken = localStorage.getItem("userToken");
    try {
      const response = await fetch(
        SERVICE_URL + "/api/getTraineeScheduleDetailsById",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return { data: data };
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error) {
      thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const getSchedulerDetailsById = createAsyncThunk(
  "users/getSchedulerDetailsById",
  async (value, thunkAPI) => {
    const jwtToken = localStorage.getItem("userToken");
    try {
      const response = await fetch(
        SERVICE_URL + "/api/getSchedulerDetailsById",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            value,
          }),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return { data: data };
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error) {
      thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const getProfileDetails = createAsyncThunk(
  "users/getProfileDetails",
  async (thunkAPI) => {
    const jwtToken = localStorage.getItem("userToken");
    try {
      const response = await fetch(SERVICE_URL + "/api/getProfileDetails", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });
      let data = await response.json();
      if (response.status === 200) {
        return { data: data };
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error) {
      thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const bookTraineeSlot = createAsyncThunk(
  "users/bookTraineeSlot",

  async (value, thunkAPI) => {
  //  console.log(value);
    const jwtToken = localStorage.getItem("userToken"); 
    try {
      const response = await fetch(SERVICE_URL + "/api/bookTraineeSlot", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          value,
        }),
      });
      let data = await response.json();
      if (response.status === 200) {
        return { data: data };
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error) {
      thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const uploadTrainerProfile = createAsyncThunk(
  "users/uploadTrainerProfile",
  async (details, thunkAPI) => {
    const jwtToken = localStorage.getItem("userToken");
    try {
      const response = await fetch(SERVICE_URL + "/api/uploadTrainerProfile", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          // Accept: 'application/json',
          // 'Content-Type': 'application/json',
        },
        body: details,
        // body: JSON.stringify({
        //   details
        // }),
      });
      let data = await response.json();
      if (response.status === 200) {
        return { data: data };
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error) {
      thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const updateTrainerProfile = createAsyncThunk(
  "users/updateTrainerProfile",
  async (values, thunkAPI) => {
    const {details, onSuccess} = values
    const jwtToken = localStorage.getItem("userToken");
    try {
      const response = await fetch(SERVICE_URL + "/api/updateTrainerProfile", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        body: details,
        // JSON.stringify({
        //   details
        // }),
      });
      let data = await response.json();
      if (response.status === 200) {
        onSuccess()
        return { data: data };
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error) {
      thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const setLoginUser = createAsyncThunk("users/setLoginUser", async (values,thunkAPI) => { 
  try {  
    const response = await fetch(SERVICE_URL + "/api/loginHistory", {
      method: "POST",
      headers: { 
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    });
   
    let data = await response.json();
    if (response.status === 200) {
      return { data: data, user: data };
    } else { 
      return thunkAPI.rejectWithValue(response);
    }
  } catch (error) {
    thunkAPI.rejectWithValue(error.response);
  }
});


export const getOnlineUsersData = createAsyncThunk(
  "users/getOnlineUsersData",
  async (details, thunkAPI) => {
    const jwtToken = localStorage.getItem("userToken");
    try {
      const response = await fetch(SERVICE_URL + "/api/getOnlineUsersData", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          // Accept: 'application/json',
          // 'Content-Type': 'application/json',
        },
        body: details,
        // body: JSON.stringify({
        //   details
        // }),
      });
      let data = await response.json();
      console.log(data)
      if (response.status === 200) {
        console.log(data);
        return { data: data };
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error) {
      thunkAPI.rejectWithValue(error.response);
    }
  }
);


const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    //empty state
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.isbookTraineeSlotSuccess = false;
      state.isSlotBookedAlready=false;
      state.isSuccessuploadTrainerProfile = false;
      state.isDeleteScheduleByIdSuccess = false;
      state.isCancelScheduleByIdSuccess = false;
      state.isConfirmScheduleByIdSuccess = false;
      return state;
    },
  },
  extraReducers: {
    // signUpUser
    [signUpUser.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.isLoginSuccess = true;
      state.isError = false;
      state.signUpSuccessResponse = payload;
    },
    [signUpUser.pending]: (state) => {
      state.isFetching = true;
    },
    [signUpUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },

    // loginUser
    [loginUser.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isLoginSuccess = true;
      state.isError = false;
      state.logInSuccessResponse = payload;
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
    [loginUser.pending]: (state) => {
      state.isFetching = true;
    },

    // fetchUserByToken
    [getUser.pending]: (state) => {
      state.isFetchingUser = true;
    },
    [getUser.fulfilled]: (state, { payload }) => {
      state.isFetchingUser = true;
      state.isFetchUserSuccessResponse = payload.data;
      state.user = payload.data;
    },
    [getUser.rejected]: (state) => {
      state.isFetchingUser = false;
      state.isError = true;
    },
    //set Login User
    [setLoginUser.pending]: (state) => {
      state.isFetchingUser = true;
    },
    [setLoginUser.fulfilled]: (state, { payload }) => {
      state.isFetchingUser = true;
      state.isFetchUserSuccessResponse = payload.data;
      state.user = payload.data;
    },
    [setLoginUser.rejected]: (state) => {
      state.isFetchingUser = false;
      state.isError = true;
    },
    // getSchedulers
    [getSchedulers.pending]: (state) => {
      state.isFetching = true;
    },
    [getSchedulers.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.CheckTrainerSchedulingresponse = payload.data;
      // return state;
    },
    [getSchedulers.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
    },

    // getScheduler
    [getSchedulerBySearch.pending]: (state) => {
      state.isFetching = true;
      state.CheckTrainerSchedulingresponse = [];
    },
    [getSchedulerBySearch.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.CheckTrainerSchedulingresponse = payload.data;
      // return state;
    },
    [getSchedulerBySearch.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
    },

    // deleteSchedule
    [deleteScheduleById.pending]: (state) => {
      state.isFetching = true;
      state.isDeleteScheduleByIdSuccess = false;
    },
    [deleteScheduleById.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = false;
      state.isDeleteScheduleByIdSuccess = true;
    },
    [deleteScheduleById.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
      state.isDeleteScheduleByIdSuccess = false;
    },

    // condfirmSchedule
    [confirmScheduledById.pending]: (state) => {
      state.isFetching = true;
      state.isConfirmScheduleByIdSuccess = false;
    },
    [confirmScheduledById.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = false;
      state.isConfirmScheduleByIdSuccess = true;
    },
    [confirmScheduledById.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
      state.isConfirmScheduleByIdSuccess = false;
    },

    // cancelSchedule
    [cancelScheduledById.pending]: (state) => {
      state.isFetching = true;
      state.isCancelScheduleByIdSuccess = false;
    },
    [cancelScheduledById.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = false;
      state.isCancelScheduleByIdSuccess = true;
    },
    [cancelScheduledById.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
      state.isCancelScheduleByIdSuccess = false;
    },

    // getScheduler
    [getScheduleDetailsById.pending]: (state) => {
      state.isFetching = true;
    },
    [getScheduleDetailsById.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.TrainerSchedules = payload.data;
      // return state;
    },
    [getScheduleDetailsById.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
    },

    // getScheduler
    [getScheduledDetailsByTrainerId.pending]: (state) => {
      state.isFetching = true;
    },
    [getScheduledDetailsByTrainerId.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.TraineeBookedSchedules = payload.data;
      // return state;
    },
    [getScheduledDetailsByTrainerId.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
    },

    //schduleTrainer
    [scheduleTrainer.pending]: (state) => {
      state.isFetching = true;
    },
    [scheduleTrainer.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isScheduleTrainerSuccess = true;
      state.TrainerScheduling = payload?.data ?? [];
      // return state;
    },
    [scheduleTrainer.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
    },

    [getAllTrainerNames.pending]: (state) => {
      state.isFetching = true;
    },
    [getAllTrainerNames.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.getAllTrainerNamesResponse = payload.data;
      // return state;
    },
    [getAllTrainerNames.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
    },

    [getTraineeScheduleDetailsById.pending]: (state) => {
      state.isFetching = true;
    },
    [getTraineeScheduleDetailsById.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.TraineeSchedulesResponse = payload.data;
      // return state;
    },
    [getTraineeScheduleDetailsById.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
    },

    [getSchedulerDetailsById.pending]: (state) => {
      state.isFetching = true;
    },
    [getSchedulerDetailsById.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.getSchedulerDetailsByIdResponse = payload.data;
      // return state;
    },
    [getSchedulerDetailsById.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
    },

    [bookTraineeSlot.pending]: (state) => {
      state.isFetching = true;
      state.isbookTraineeSlotSuccess = false;
      state.isSlotBookedAlready=false;
    },
    [bookTraineeSlot.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isbookTraineeSlotSuccess = true; 
        if(payload.data.data.rowscount<=0)
      {
    //    console.log('payload.data.rowscount'+payload.data.data.rowscount)
        state.isSlotBookedAlready=false;
      }else 
      state.isSlotBookedAlready=true;
      // return state;
    },
    [bookTraineeSlot.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
      state.isbookTraineeSlotSuccess = false;
      state.isSlotBookedAlready=false;
    },

    [getProfileDetails.pending]: (state) => {
      state.isFetching = true;
      state.isSuccess = false;
    },
    [getProfileDetails.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.getProfileDetailsResponse = payload.data;
      // return state;
    },
    [getProfileDetails.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
    },

    [updateTrainerProfile.pending]: (state) => {
      state.isFetching = true;
      state.updateTrainerProfileisSuccess = false;
    },
    [updateTrainerProfile.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.updateTrainerProfileisSuccess = true;
      // return state;
    },
    [updateTrainerProfile.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
      state.updateTrainerProfileisSuccess = false;
    },
    [uploadTrainerProfile.pending]: (state) => {
      state.isFetching = true;
    },
    [uploadTrainerProfile.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccessuploadTrainerProfile = true;
      console.log(state.isSuccessuploadTrainerProfile, "in slice");
      state.getProfileDetailsResponse = payload.data;
      // return state;
    },
    [uploadTrainerProfile.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
    },
    //getonlineprofiles 
    [getOnlineUsersData.pending]: (state) => {
      state.isFetching = true;
      state.getOnlineUsersDataisSuccess = false;
    },
    [getOnlineUsersData.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.getOnlineUsersDataResponse = payload.data;  
      state.getOnlineUsersDataisSuccess = true;
    
        return state;
    },
    [getOnlineUsersData.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
      state.getOnlineUsersDataisSuccess = false;
    },
  },
});
export const { service_url } = SERVICE_URL;
export default userSlice.reducer;
export const { clearState } = userSlice.actions;
export const userSelector = (state) => state.user;
