import React, { Fragment, useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import moment from "moment";
import {
  userSelector,
  getUser,
  clearState,
} from "../../pages/features/userSlice";
import {
  deleteScheduleById,
  getDetailsById,
  getScheduleDetailsById,
} from "../../pages/features/userSlice";
import { getTraineeScheduleDetailsById } from "../../pages/features/userSlice";
import { toast } from "react-toastify"; 
import Sidenav from "../sidenav/sidenav";
import { SuccessNotification } from "../../pages/notifications/notification";
import "./dashboard.css";
import { getScheduledDetailsByTrainerId } from "../../pages/features/userSlice";
import { Context } from "../../pages/features/storeContext";
import TraineeScheduleTable from "./TraineeScheduleTable";
import highlightWeekends from "react-multi-date-picker/plugins/highlight_weekends";
import { Typography, Row, Col, Table } from "antd";

const DashBoard = () => {
  const [record, setRecord] = useState([]);

  const getData = () => {
    fetch("https://jsonplaceholder.typicode.com/users")
      .then((resposne) => resposne.json())
      .then((res) => setRecord(res));
  };

  const dateFormat = (value) => {
    value = value.replace(/:[^:]*$/, "");
    console.log(value);

    value = value.replace(/^0+/, "");
    const hourMin = value.split(":");
    var hour = hourMin[0];
    var min = hourMin[1];

    var AmOrPm = hour >= 12 ? "pm" : "am";
    hour = hour % 12 || 12;
    //var minutes = dt.getMinutes() ;

    value = hour + ":" + min + "" + AmOrPm;

    console.log(value);

    // return value.replace(/^0+/, '')
    return value;

    // return value.replace(/^(?:00:)?0?/, '').splice(:2);
    // return new Date().toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'});
  };
  const { isFetchUserSuccess, isFetchUserSuccessResponse } =
    useSelector(userSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    isFetching,
    isSuccess,
    isError,
    errorMessage,

    TrainerSchedules,
  } = useSelector(userSelector);

  const { TraineeSchedulesResponse } = useSelector(userSelector);

  useEffect(() => {
    // dispatch(getUser());
    dispatch(getScheduleDetailsById());
    dispatch(getScheduledDetailsByTrainerId());
    dispatch(getTraineeScheduleDetailsById());
  }, []);
  const bookedStatus = (value) => {
    if (value == "B") {
      return "Booked";
    } else if (value == "C") {
      return "Confirmed";
    } else if (value == "D") {
      return "Cancel";
    } else return "";

    // return value.replace(/^(?:00:)?0?/, '').splice(:2);
    // return new Date().toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'});
  };
  if (isFetchUserSuccess) {
    console.log(
      "am in dashboard with isFetchUserSuccess",
      isFetchUserSuccessResponse
    );

    console.log("am in dashboard with trainer Schedules", TrainerSchedules);

    console.log(
      "am in dashboard with trainee Schedules",
      TraineeSchedulesResponse
    );
    localStorage.setItem("role", isFetchUserSuccessResponse?.[0]?.role);
  }
  const dayFormat = (eventDate) => {
    console.log(eventDate);

    const date1 = new moment(eventDate, "YYYY-MM-DD hh:mm:ss").format(
      " MMMM Do, YYYY"
    );
    return date1;
  };
  const hrefValue = (value) => {
    return (
      "https://pocdemo.aitacs.com/" +
      value +
      "#userInfo.displayName=" +
      isFetchUserSuccessResponse[0].full_name
    );
  };

  const columns = [
    {
      title: "Trainer Name",
      dataIndex: "full_name",
      key: 1,
    },
    {
      title: "Trainer Email",
      dataIndex: "email",
      key: 2,
    },
    {
      title: "Booked Status",
      dataIndex: "status",
      key: 3,
      render: (value) => {
        return bookedStatus(value);
      },
    },
    {
      title: "Start Time",
      dataIndex: "scheduled_date",
      key: 4,
      render: (value) => {
        return dayFormat(value);
      },
    },
    {
      title: "Meeting Link",
      dataIndex: "meetingcode",
      key: 5,
      render: (meetingLink) => {
        return (
          <>
            {meetingLink && (
              <a href={hrefValue(meetingLink)} target="_blank">
                Start{" "}
              </a>
            )}
          </>
        );
      },
    },
  ];
  const traineeBookedSessions = TraineeSchedulesResponse.data
    ?.slice(0, 15)
    .map((data) => {
      return data;
    });
  return (
    <div>
      <section className="dashboard_info">
        <Row>
          <Col span={23}>
            <Typography.Title level={4} className="section-title">
              Welcome {isFetchUserSuccessResponse?.[0]?.full_name}
            </Typography.Title>

            {isFetchUserSuccessResponse?.[0]?.role === "Trainee" ? (
              <>
                <div class="col main mt-3">
                  <nav aria-label="breadcrumb"></nav>
                  <p class="lead d-none d-sm-block">
                    <h3>Booked Sessions</h3>
                  </p>

                  <div
                    class="alert alert-warning fade collapse"
                    role="alert"
                    id="myAlert"
                  >
                    <button
                      type="button"
                      class="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                      <span class="sr-only">Close</span>
                    </button>
                    <strong>Data and Records</strong> Learn more about employee
                  </div>
                  <div class="row mb-3">
                    {/*   <div class="col-xl-4 col-sm-7 py-2">
                <div class="card bg-success text-white h-100">
                    <div class="card-body bg-success" style={{backgroundColor:"#57b960"}}>
                   
                        <h6 >Total your Booked Sessions</h6>
                        <h1 class="display-4">20</h1>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-sm-6 py-2">
                <div class="card text-white bg-danger h-100">
                    <div class="card-body bg-danger">
                        <div class="rotate">
                            <i class="fa fa-list fa-4x"></i>
                        </div>
                        <h6 class="text-uppercase">No.Of Trainers</h6>
                        <h1 class="display-4">87</h1>
                    </div>
                </div>
            </div> */}
                  </div>
                </div>

                {/* trianeee Scheduled Details */}

                <div class="table-responsive">
                  {TraineeSchedulesResponse.data ? (
                    <>
                      <Table
                        columns={columns}
                        dataSource={traineeBookedSessions}
                        pagination={false}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            ) : (
              <>
                <div class="col main pt-5 mt-3"></div>

                <div class="row ">
                  <div class="col-lg-4 col-md-4 col-sm-7">
                    <h5
                      class="mt-3 mb-3 text-secondary"
                      style={{ fontWeight: "bold", fontSize: "18px" }}
                    >
                      Scheduled Openings
                    </h5>
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead class="thead-light">
                          <tr>
                            <th>Day</th>
                            <th>Start Time</th>
                            <th>End Time</th>
                          </tr>
                        </thead>
                        <tbody>
                          {TrainerSchedules.data?.slice(0, 5).map((data) => (
                            <tr>
                              <td>{data.day} </td>
                              <td>{dateFormat(data.start_time)}</td>
                              <td>{dateFormat(data.end_time)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-9 col-sm-13   ">
                    {/* <h4 className='title mt-3 mb-3 text-center text-secondary'>Trainee Scheduled Sessions</h4> */}
                    <div
                    
                  
                    >
                      <TraineeScheduleTable />{" "}
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* tablerecords */}
          </Col>
        </Row>
      </section>
    </div>
  );
};

export default DashBoard;
