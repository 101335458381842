import React, { Component } from 'react'
import Logo from "../../assets/images/logo1.png";


const FooterComponent = () => {


    return (
        <div>
            <footer>
                <div className="row">
                    <div className="col-md-3">
                        <img src={Logo} />
                    </div>
                    <div className="col-md-3">
                        <h4>Quick Links</h4>
                        <ul>
                            <li><a href="#">Book Lesson </a></li>
                            <li><a href="#">MyCommunity</a></li>
                            <li><a href="#">web & Apps</a></li>
                            <li><a href="#">Store</a></li>
                            <li><a href="#">Contact us</a></li>
                        </ul>
                    </div>
                    <div className="col-md-3">
                        <h4>Legal</h4>
                        <ul>
                            <li><a href="#">Privacy Policy</a></li>
                            <li><a href="#">Terms of Service</a></li>
                            <li><a href="#">Acceptable Use Policy</a></li>
                        </ul>
                    </div>
                    <div className="col-md-3">
                        <h4>Social Media</h4>

                    </div>
                </div>
            </footer>
            <div className="copyright">
                <p>2023 © NetQwix. All Rights reserved</p>
            </div>
        </div>
    )

}

export default FooterComponent
