import { useSelector, useDispatch } from "react-redux";
import {
  userSelector,
  getUser,
  getSchedulers,
  getScheduler,
  getAllTrainerNames,
  getSchedulerBySearch,bookTraineeSlot,
  clearState,getOnlineUsersData
} from "../../../pages/features/userSlice";
import { Divider, Select, Typography, Row, Col, Modal, Button } from "antd";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import "./checkTrainerScheduling.css";
import OpenModal from "../modal/modal";
import { Context } from "../../../pages/features/storeContext";
import Multiselect from "multiselect-react-dropdown";

//import { dayjs } from 'dayjs';
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Sidenav from "../../sidenav/sidenav";
import DemoSecond from "./DemoSecond";
import { AlredyBookedSlotNotification, SuccessScheduleTrainerNotification } from "../../../pages/notifications/notification";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { confirm } from "react-confirm-box";
const CheckTrainerScheduling = (props) => {
  const {
    isFetchUserSuccessResponse,
    CheckTrainerSchedulingresponse,
    getAllTrainerNamesResponse,getOnlineUsersDataResponse,
    isbookTraineeSlotSuccess,isSlotBookedAlready
  } = useSelector(userSelector);

  const [timeValue, setTimeValue] = React.useState(0);
  const [openModal, setOpenModel] = useState(false);
  // React.useState<Dayjs | null>(dayjs('2022-04-07')
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [onlineUsers,setOnlineUsers]=useState([]);
  const [Option, setOption] = useState({
    set: [],
    onDaySelect: [],
    searchedTime: ""
  });
  
  const state = {
    options: [
      { name: "Monday" },
      { name: "Tuesday" },
      { name: "Wednesday" },
      { name: "Thursday" },
      { name: "Friday" } 
    ],
    optionsWithLabelChange2: {
      closeOnOverlayClick: false,
      labels: {
        confirmable: "Confirm",
        cancellable: "Cancel"
      }
    }
  };

  useEffect(() => {
       //dispatch(getAllTrainerNames())
     // dispatch(getUser());
       dispatch(getAllTrainerNames())
    dispatch(clearState());
    dispatch(getSchedulers());
    console.log("CheckTrainerSchedulingresponse");
    console.log(CheckTrainerSchedulingresponse);
  }, []);

  if(isbookTraineeSlotSuccess){
    console.log(isSlotBookedAlready);
    if(isSlotBookedAlready)
    {

      AlredyBookedSlotNotification();
    }else 
    {
    //  alert('not booked')
      navigate('/trainee-schedules');
    } 
    //navigate('/trainee-schedules');
   }

  const handleChange = (Options) => {
    console.log(timeValue);
    console.log(dayjs(timeValue).hour());

    if (timeValue !== 0)
      Option.searchedTime = timeValue.hour() + ":" + timeValue.minute();
    else Option.searchedTime = "";
    console.log("----------------");
    console.log(Option);
    console.log("----------------");
    dispatch(getSchedulerBySearch(Option));
  };

 
  const ShowUserDetails = (value) => {};

  const onSelect = (selectedList, selectedItem) => {
    Option.set = selectedList;
    setOption({set:selectedItem})
    handleChange();
    // dispatch(getSchedulerBySearch(Option));
     console.log(selectedItem)
    //  console.log(getAllTrainerNamesResponse.data)
  };

  const onRemove = (selectedList, removedItem) => {
    Option.set = selectedList;
    handleChange();
  };

  const onDaySelect = (selectedList, selectedItem) => {
    Option.onDaySelect = selectedList;
    handleChange();
    //handleChange(Option);
    //  dispatch(getSchedulerBySearch(Option));

    //  console.log(getAllTrainerNamesResponse.data)
  };

  const onDayRemove = (selectedList, removedItem) => {
    Option.onDaySelect = selectedList;
    handleChange();
    //handleChange(Option);
  };
  const [another, setAnother] = useState("");

  const parentToChild = () => {
    console.log("parent to child");
    console.log(another);
    setAnother(Option.set);
  };
  const handleOk = () => {
    setOpenModel(false);
  };

  const handleCancel = () => {
    setOpenModel(false);
  };
  const settingModal = () => {
    dispatch(getOnlineUsersData())
    console.log('--------');
    console.log(getOnlineUsersDataResponse);
    setOnlineUsers(getOnlineUsersDataResponse);
    console.log('----------');
    setOpenModel(true);
  };
  const  bookSlot2 = async ( data) => {  
    setOpenModel(false);
   console.log(data)
    const CURRENT_DATE=  new moment(new Date(),"YYYY-MM-DD");
    let n = moment(new Date());
    let start_time = moment(new Date())
      .set({
        minute: n.minute()+10,
        second: 0, 
      })
      .format("HH:mm")
      .toString();
      let end_time = moment(new Date())
      .set({
        minute: n.minute()+40,
        second: 0, 
      })
      .format("HH:mm")
      .toString();    
      const confirmresult =  await confirm(`You are booking a lesson with  ${data.full_name} at ${start_time} on  ${ moment(new Date()).format("dddd, MMMM Do")} .`, 
    state.optionsWithLabelChange2);
      if (confirmresult) {
       console.log('details')
    const details = { "start":CURRENT_DATE, "bookingStatus":"Instant", "start_time": start_time, "end_time": end_time, "user_id": data.id };
    console.log(details);
         dispatch(bookTraineeSlot(details)); 
        return;
      }
  


  }
  return (
    <div>
      <section className="dashboard_info">
        <Row>
          <Col span={23}> 
            <Typography.Title level={4} className="section-title">Book Training Session</Typography.Title>
            { 
                        <div className="row">
 <div className="col-md-6">
                            <div className="checkschedulecaption"> Search by Trainer Name </div>
                                <Multiselect
                                    options={getAllTrainerNamesResponse.data} // Options to display in the dropdown
                                    selectedValues={state.id} // Preselected value to persist in dropdown
                                    onSelect={onSelect} // Function will trigger on select event
                                    onRemove={onRemove} // Function will trigger on remove event
                                    displayValue="full_name"
                                    hasSelectAll={true} // Property name to display in the dropdown options 
                                    label={"Select By"} /></div>
<div className="col-md-3">
                            <div  className="checkschedulecaption"> Search by Date</div>
                                <Multiselect
                                    options={state.options} // Options to display in the dropdown
                                    selectedValues={state.selectedValue} // Preselected value to persist in dropdown
                                    onSelect={onDaySelect} // Function will trigger on select event
                                    onRemove={onDayRemove} // Function will trigger on remove event
                                    displayValue="name"
                                    hasSelectAll={true} // Property name to display in the dropdown options
                                /></div>
                                <div className="col-md-3">
                                <div className="checkschedulecaption"> &nbsp;</div> <Button
                          className="updateProfile"
                          type="primary"  style={{marginTop:10}}
                          onClick={() =>
                            settingModal()
                          }
                        >
                          Schedule Instant Meeting
                        </Button> </div>


<Modal  style={{marginTop:10}}
                      title="Schedule Instant Meeting"
                      open={openModal}
                      centered
                      onOk={handleOk}
                      onCancel={handleCancel}
                      footer={null}
                      width={700}
                      >
                      <div>
                        <div className="row"> 
                        <table class="table table-striped">
                        <thead class="thead-light">
                          <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Instant Meeting Request</th>
                          </tr>
                        </thead>
                        <tbody>
                          {getOnlineUsersDataResponse.data?.slice(0, 5).map((data) => (
                            <tr>
                              <td>{data.full_name} </td>
                              <td>{data.email}</td>
                              <td> 
                              
                              <button
                                    className="btn btn-primary"
                                onClick={() =>bookSlot2(data)}
                                  >
                                    Book Instant Meeting
                                  </button></td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                           
                          {/*<Row>
                              <Col span={6} style={{ marginLeft: "500px" }}>
                                <div
                                // style={{
                                //   justifyContent: "end",
                                //   display: "flex",
                                // }}
                                >
                                  <button
                                    className="btn btn-primary"
                                    type="submit"
                                  >
                                    Update
                                  </button>
                                  <button
                                    className="btn btn-danger"
                                    style={{ marginLeft: "5px" }}
                                    onClick={handleCancel}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </Col>
                            </Row>  */}  
                        </div>
                      </div>
                    </Modal>

                           
                          {/*
                            <div className="col-md-3">
                            <div  className="checkschedulecaption"> Search by Time </div>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimePicker
                                     minutesStep="5"
                                        value={timeValue}
                                        onChange={(newValue) => {
                                            setTimeValue(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </div> */}


                        </div>  }</Col></Row> 
                         
                        
                        <Row>
          <Col span={23}>
            {CheckTrainerSchedulingresponse?.scheduleArray?.length > 0 ? (
              <>
                <div className="row">
                  <DemoSecond
                    data={CheckTrainerSchedulingresponse.usernames}
                    parentToChild={CheckTrainerSchedulingresponse.scheduleArray}
                  />
                </div>
              </>
            ) : (
              <></>
            )}

            { /*CheckTrainerSchedulingresponse?.data?.map((data) =>
                            <section className="search-result-item">
                                <a className="image-link" href="#">
                                    <img className="image" src="https://bootdey.com/img/Content/avatar/avatar1.png" />
                                </a>
                                <div className="search-result-item-body">
                                    <div className="row">
                                        <div className="col-sm-9">
                                          
                                            <h4 className="search-result-item-heading">
                                                    <p className='traineebook'>  Trainer name:  <a href="#">{data.full_name}   </a> </p> 
                                                    <p className='traineebook'>  Trainer Email:  <a href="mailto:{data.email}"> {data.email}  </a></p>
                                            
                                                    </h4>
                                                    <p className="description traineebook">  Day & Time: <a href="#">{data.day} & {data.start_time}-{data.end_time}</a></p>
                                                   
                                        </div>
                                        <div className="col-sm-3 text-align-center">
                                            <p className="value3 mt-sm">${data.price} PER Session</p>
                                            <button className="btn btn-primary btn-info btn-sm" onClick={() => ShowUserDetails(data.id)}>Check More</button>
                                        </div>
                                    </div>
                                </div>
                            </section>
            )*/  }
            { /* <section className="search-result-item">
                            <a className="image-link" href="#">
                                <img className="image" src="https://bootdey.com/img/Content/avatar/avatar6.png" />
                            </a>
                            <div className="search-result-item-body">
                                <div className="row">
                                    <div className="col-sm-9">
                                        <h4 className="search-result-item-heading">
                                            <a href="#">john doe</a>
                                            <span className="badge bg-danger fw-normal pull-right">Best Deal!</span>
                                        </h4>
                                        <p className="info">Los Angeles, NY 20188</p>
                                        <p className="description">You will never know exactly how something will go until you try it. You can think three hundred times and still have no precise result.</p>
                                    </div>
                                    <div className="col-sm-3 text-align-center">
                                        <p className="value3 mt-sm">$10, 300</p>
                                        <p className="fs-mini text-muted">PER WEEK</p>
                                        <a className="btn btn-primary btn-info btn-sm" href="#">Learn More</a>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="search-result-item">
                            <a className="image-link" href="#">
                                <img className="image" src="https://bootdey.com/img/Content/avatar/avatar2.png" /> </a>
                            <div className="search-result-item-body">
                                <div className="row">
                                    <div className="col-sm-9">
                                        <h4 className="search-result-item-heading">
                                            <a href="#">john doe</a>
                                        </h4>
                                        <p className="info">New York, NY 20188</p>
                                        <p className="description">The Great Prince of the Grand Duchy of Lithuania he had stopped the invasion to Europe of Timur (Tamerlan) from Asia heading a big Army of Belarusians, Lithuanians.</p>
                                    </div>
                                    <div className="col-sm-3 text-align-center">
                                        <p className="value3 mt-sm">$3, 200</p>
                                        <p className="fs-mini text-muted">PER WEEK</p>
                                        <a className="btn btn-info btn-sm" href="#">Learn More</a>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="search-result-item">
                            <a className="image-link" href="#">
                                <img className="image" src="https://bootdey.com/img/Content/avatar/avatar3.png" /> </a>
                            <div className="search-result-item-body">
                                <div className="row">
                                    <div className="col-sm-9">
                                        <h4 className="search-result-item-heading">
                                            <a href="#">john doe</a>
                                        </h4><p className="info">Minsk, NY 20188</p>
                                        <p className="description">Yes you can! Further more, you should! It let's you create really beautiful images either for elements or for the entire background.</p>
                                    </div>
                                    <div className="col-sm-3 text-align-center">
                                        <p className="value3 mt-sm">$2, 400</p>
                                        <p className="fs-mini text-muted">PER MONTH</p>
                                        <a className="btn btn-info btn-sm" href="#">Learn More</a>
                                    </div>
                                </div>
                            </div>
            </section>*/  }
          </Col>
        </Row>
      </section>
    </div>
  );
};
export default CheckTrainerScheduling;
