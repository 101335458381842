import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  cancelScheduledById,
  clearState,
  confirmScheduledById,
  deleteScheduleById,
  getDetailsById,
  getScheduledDetailsByTrainerId,
  getUser,
  userSelector,
} from "../../../pages/features/userSlice";
import {
  SuccessCancelScheduleNotification,
  SuccessConfirmScheduleNotification,
} from "../../../pages/notifications/notification";
import "./traineeBookedSchedules.css";
import { Col, Row, Typography } from "antd";
import moment from 'moment';
// import "./index.css"

const TraineeBookedSchedules = () => {
  const {
    isFetching,
    isSuccess,
    isConfirmScheduleByIdSuccess,
    isCancelScheduleByIdSuccess,
    getUserresponse,
    TraineeBookedSchedules,
  } = useSelector(userSelector);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getScheduledDetailsByTrainerId());
    if (isCancelScheduleByIdSuccess) {
      SuccessCancelScheduleNotification();
      dispatch(clearState());
    }
    if (isConfirmScheduleByIdSuccess) {
      SuccessConfirmScheduleNotification();
      dispatch(clearState());
    }
  }, [isCancelScheduleByIdSuccess, isConfirmScheduleByIdSuccess]);

  const deleteSchedule = (value) => {
    console.log(value);
    dispatch(cancelScheduledById(value));
  };
  const confirmScheduled = (value) => {
    console.log(value);
    dispatch(confirmScheduledById(value));
  };

  if (isSuccess) {
    console.log("am in TraineeBookedSchedules", TraineeBookedSchedules);
  }
  
  const hrefValue = (value) =>{
    return "https://pocdemo.aitacs.com/"+value+"#userInfo.displayName=";
  }
  const dateFormat = (value) => {
    
    value = value.replace(/:[^:]*$/, "");
    console.log(value);

    value = value.replace(/^0+/, "");
    const hourMin = value.split(":");
    var hour = hourMin[0];
    var min = hourMin[1];

    var AmOrPm = hour >= 12 ? "pm" : "am";
    hour = hour % 12 || 12;
    //var minutes = dt.getMinutes() ;

    value = hour + ":" + min + "" + AmOrPm;

    console.log(value);

    // return value.replace(/^0+/, '')
    return value;
    // return value.replace(/^(?:00:)?0?/, '').splice(:2);
    // return new Date().toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'});
  };

  const format = (value) => {
    let newDate = new Date(value);
    let date = newDate.getDate() - 1;
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    return `${year}-${month < 10 ? `0${month}` : `${month}`}-${date}`;
  };
  const dayFormat=(eventDate)=>
  { 
    const date1=  new moment(eventDate).format("MMM Do, YYYY");
    return date1;
  }
  const dayValueFormat=(eventDate)=>
  { 
    const date1=  new moment(eventDate).format("dddd");
    return date1;
  }
  return (
    <div className="tab-content">
      {/* <div className="row">
									<div className="col-md-3"><img src={Image1} /></div>
									<div className="col-md-3"><img src={Image2} /></div>
									<div className="col-md-3"><img src={Image3} /></div>
									<div className="col-md-3"><img src={Image4} /></div>
									<div className="col-md-3"><img src={Image5} /></div>
									<div className="col-md-3"><img src={Image6} /></div>
									<div className="col-md-3"><img src={Image7} /></div>
									<div className="col-md-3"><img src={Image8} /></div>
									<div className="col-md-3"><img src={Image9} /></div>
									<div className="col-md-3"><img src={Image10} /></div>
									<div className="col-md-3"><img src={Image11} /></div>
									<div className="col-md-3"><img src={Image12} /></div>
								</div> */}
       {TraineeBookedSchedules.data?.length?'':'No Training Sessions Scheduled'}
      {TraineeBookedSchedules.data?.map((data) => (
        <section className="search-result-item">
          {
            <a className="image-link" href="#">
              {data?.file_name ? (
                <img
                className="image"
                style={{ borderRadius: "30px",width: 100,
                height: 100 ,padding:15}}
                  src={`http://netqwix.aitacs.com:5200/uploadedFile/${data.file_name}`}
                />
              ) : (
                <img      className="image"
                style={{ borderRadius: "30px",width: 100,
                height: 100 ,padding:15}}
               
                  src="https://bootdey.com/img/Content/avatar/avatar1.png"
                />
              )}
            </a>
          }
          <div className="d-flex flex-column booked-sesions">
            <Row>
              <Col span={8}>
                <Typography.Title level={5}>Trainee name:</Typography.Title>
              </Col>
              <Col span={12}>{data.full_name}</Col>
            </Row>
            <Row>
              <Col span={8}>
                <Typography.Title level={5}>Date:</Typography.Title>
              </Col>
              <Col span={12}>{dayFormat(data.scheduled_date)}</Col>
            </Row>
            <Row>
              <Col span={8}>
                <Typography.Title level={5}>Current Status:</Typography.Title>
              </Col>
              <Col span={12}>
                {data.status === "C" ? "Confirmed" : ""}
                {data.status === "D" ? "Cancel" : ""}
                {data.status === "B" ? "Booked" : ""}{" "}
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <Typography.Title level={5}>
                  Booked Day & Time:
                </Typography.Title>
              </Col>
              <Col span={12}>
                {dayValueFormat(data.scheduled_date)}, {dateFormat(data.start_time)} -{" "}
                {dateFormat(data.end_time)}
              </Col>
            </Row>
            <div
              className="d-flex"
              style={{ marginTop: "10px",}}
            >
              {data.status === "C" ? (
                <>
                  <button
                    className="btn btn-danger btn-info btn-sm"
                    onClick={() => deleteSchedule(data.scheduled_id)}
                  >
                    Cancel
                  </button>
                  {/*
                  <button
                    className="btn btn-primary btn-info btn-sm"
                    style={{ marginLeft: "10px" }}
                    
                  >
                    Confirmed 
                  </button> */}
                  {data.meetingcode?<><a      style={{ marginLeft: "10px" }} className="btn btn-primary btn-info btn-sm" href={hrefValue(data.meetingcode)} target="_blank">Start </a></>:<></>}
                </>
              ) : (
                ""
              )}
              {data.status === "D" ? (
                <>
                  <button className="btn btn-danger btn-info btn-sm" disabled>
                    Cancelled
                  </button>
                  <button
                    className="btn btn-primary btn-info btn-sm"
                    style={{ marginLeft: "10px" }}
                    onClick={() => confirmScheduled(data.scheduled_id)}
                  >
                    Confirm
                  </button>
                </>
              ) : (
                ""
              )}
              {data.status === "B" ? (
                <>
                  <button
                    className="btn btn-danger btn-info btn-sm"
                    onClick={() => deleteSchedule(data.scheduled_id)}
                  >
                    Cancel
                  </button>
                   <button
                    className="btn btn-primary btn-info btn-sm"
                    style={{ marginLeft: "10px" }}
                    onClick={() => confirmScheduled(data.scheduled_id)}
                  >
                    Confirm
                  </button>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </section>
      ))}
    </div>
  );
};

export default TraineeBookedSchedules;
