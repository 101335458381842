import { Modal, Button, Form, Input } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { resetPassword } from "../../pages/features/userSlice";
//import ForgotPasswordOtp from "./ForgotPasswordOtp";
import { useNavigate } from "react-router-dom";

const ResetPasswordForm = ({
  setShowResetPwd,
  setShowSignup,
  setEmail,
  setNewPwd,
  setConfirmPwd,
  setOtp,
}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onReset = async (data) => {
    console.log(data);
    setLoading(true);
    dispatch(
        resetPassword({
        values: data,
        onSuccess: () => {
          setLoading(false);
          setShowResetPwd(false);
          navigate("/dashboard");
        },
        onFailure: () => {
          setLoading(false);
        },
      })
    );
  };
  return (
    <>
      <Modal
        open={true}
        className="forgot-password-modal"
        onCancel={() => setShowResetPwd(false)}
        footer={null}
      >
        <div className="forgot-password-form">
          <div className="forgot-password-form">
            <h2>Reset Password</h2>
            <Form onFinish={onReset}>
            Please check your email for the 4 digit security code
               needed to be entered with your new password.
              <Form.Item
                name={"email"}
                //dependencies={["localStorage.getItem('otpMail')"]}
                //rules={[{ required: true, message: "Email is required" }]}
              >
                <Input
                  type="email"
                  name="email"
                  //value={localStorage.getItem('otpMail')}
                  size="large"
                  placeholder="Email address" 
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Item>
              <Form.Item
                name={"newPwd"}
                rules={[{ required: true, message: "New password is required" },
                {
                  pattern: new RegExp(
                    /(?=[A-Za-z0-9]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,}).*$/
                  ),
                  message: "Password should contain at least 1 upper case, 1 lower and 1 number with 8 characters length",
                },
                ]}
              >
                <Input
                  type="password"
                  name="newPwd"
                  size="large"
                  placeholder="New Password"
                  onChange={(e) => setNewPwd(e.target.value)}
                />
              </Form.Item>
             
              <Form.Item
                name={"confirmPwd"}
                dependencies={["newPwd"]}
                rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("newPwd") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "Passwords mismatching! Please confirm your password."
                          )
                        );
                      },
                    }),
                  ]}
              >
                <Input
                  type="password"
                  name="confirmPwd"
                  size="large"
                  placeholder="Confirm Password"
                  onChange={(e) => setConfirmPwd(e.target.value)}
                />
              </Form.Item>
              <Form.Item
                name={"otp"}
                rules={[{ required: true, message: "Security code is required" }]}
              >
                <Input
                  type="text"
                  name="otp"
                  size="large"
                  placeholder="Security Code"
                  onChange={(e) => setOtp(e.target.value)}
                />
              </Form.Item>
              <Button
                className="forget-password-btn"
                loading={loading}
                type="primary"
                htmlType="submit"
                size="large"
                block
              >
                Submit
              </Button>
              <p className="text-center py-4">
                <label>
                  Sign up to schedule your Training Session.
                  <Button
                    type="link"
                    onClick={() => {
                      setShowResetPwd(false);
                      setShowSignup(true);
                    }}
                  >
                    <b>Sign up</b>
                  </Button>
                </label>
              </p>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ResetPasswordForm;
