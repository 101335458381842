import {
  Button,
  DatePicker,
  Drawer,
  Select,
  TimePicker,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isoWeek from "dayjs/plugin/isoWeek";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  updateTrainerScheduler,
  getUser,
  getScheduleDetailsById,
} from "../../../pages/features/userSlice";
import {
  FailureScheduleTrainerNotification,
  SuccessScheduleTrainerNotification,
} from "../../../pages/notifications/notification";

dayjs.extend(customParseFormat);
dayjs.extend(isoWeek);
const UpdateTrainerSchedule = ({ setShowUpdate, currentUpdatingSchedule }) => {
  const [startTime, setStartTime] = useState(null);
  const [loading, setLoading] = useState(false);
  const [endTime, setEndTime] = useState(null);
  const [errors, setErrors] = useState({});
  const [weekDay, setWeekDay] = useState(currentUpdatingSchedule?.day);
  const dispatch = useDispatch();
  useEffect(() => {
    if (currentUpdatingSchedule?.start_time) {
      const splittedStartTime = currentUpdatingSchedule?.start_time?.split(":");
      const currentStartTime = dayjs()
        .hour(splittedStartTime?.[0])
        .minute(splittedStartTime?.[1]);
      setStartTime(currentStartTime);
    }
    if (currentUpdatingSchedule?.end_time) {
      const splittedEndTime = currentUpdatingSchedule?.end_time?.split(":");
      const currentEndTime = dayjs()
        .hour(splittedEndTime?.[0])
        .minute(splittedEndTime?.[1]);
      setEndTime(currentEndTime);
    }
    if (currentUpdatingSchedule?.day) {
      setWeekDay(currentUpdatingSchedule?.day);
    }
  }, []);
  const onUpdate = () => {
    const validations = {};
    if (!startTime) {
      validations["startTime"] = "Start time is required";
    }
    if (!endTime) {
      validations["endTime"] = "End time is required";
    }
    if (startTime) {
      if (
        (dayjs(startTime).hour() > 18 && dayjs(startTime).hour() <= 24) ||
        dayjs(startTime).hour() == 0 ||
        (dayjs(startTime).hour() >= 1 && dayjs(startTime).hour() < 6)
      ) {
        validations["startTime"] = "Night time is not allowed";
      }
      if (dayjs(endTime).hour() < dayjs(startTime).hour()) {
        validations["endTime"] = "End time should be greater than start time";
      } else if (
        dayjs(endTime).hour() > dayjs(startTime).hour() &&
        ((dayjs(endTime).hour() > 18 && dayjs(endTime).hour() <= 24) ||
          dayjs(endTime).hour() == 0 ||
          (dayjs(endTime).hour() >= 1 && dayjs(endTime).hour() < 6))
      ) {
        validations["endTime"] = "Night time is not allowed";
      } else if (
        dayjs(endTime).hour() == dayjs(startTime).hour() &&
        dayjs(endTime).minute() <= dayjs(startTime).minute()
      ) {
        validations["endTime"] = "End time should be greater than start time";
      }
    }
    if (Object.keys(validations)?.length == 0) {
      const finalStartTime = dayjs(startTime).format("HH:mm");
      const finalEndTime = dayjs(endTime).format("HH:mm");
      // console.log(finalStartTime, finalEndTime, weekDay);
      console.table(finalStartTime, finalEndTime, weekDay);
      const details = {
        id: currentUpdatingSchedule?.id,
        start_time: finalStartTime,
        end_time: finalEndTime,
        day: weekDay,
      };
      dispatch(
        updateTrainerScheduler({
          details,
          onSuccess: () => {
            setLoading(false);
            SuccessScheduleTrainerNotification();
            dispatch(getScheduleDetailsById());
            setShowUpdate(false);
          },
          onFailure: () => {
            // console.log('error')
            setLoading(false);
            // FailureScheduleTrainerNotification();
          },
        })
      );
    } else {
      setErrors(validations);
    }
  };
  const weekDays = [
    {
      label: "Monday",
      value: "Monday",
    },
    { label: "Tuesday", value: "Tuesday" },
    { label: "Wednesday", value: "Wednesday" },
    { label: "Thursday", value: "Thursday" },
    { label: "Friday", value: "Friday" },
  ];
  return (
    <Drawer
      title={currentUpdatingSchedule.day}
      placement="right"
      onClose={() => setShowUpdate(false)}
      open={true}
    >
      <div className="d-flex flex-column">
        <Typography.Text>Day</Typography.Text>
        <Select
          options={weekDays}
          value={weekDay}
          onChange={(val) => setWeekDay(val)}
        />
      </div>
      <div className="d-flex flex-column" style={{ marginTop: "10px" }}>
        <Typography.Text>Start Time</Typography.Text>
        <TimePicker
          minuteStep={15}
          secondStep={10}
          style={{ width: "100%", marginTop: "5px" }}
          placeholder="Select Start Time"
          onSelect={(value) => {
            setErrors((prevErrors) => {
              return { ...prevErrors, startTime: "" };
            });
            setStartTime(value);
            setEndTime(null);
          }}
          value={startTime}
          format="HH:mm"
          use12Hours={true}
          disabledTime={() => {
            return {
              disabledHours: () => {
                const result = [];
                for (let i = 0; i < 24; i++) {
                  if ((i > 18 && i <= 24) || i == 0 || (i >= 1 && i < 6)) {
                    result.push(i);
                  }
                }
                return result;
              },
            };
          }}
        />
        {errors?.startTime && (
          <Typography.Text style={{ color: "red", marginTop: "5px" }}>
            {errors?.startTime}
          </Typography.Text>
        )}
      </div>
      <div style={{ marginTop: "10px" }}>
        <Typography.Text>End Time</Typography.Text>
        <TimePicker
          minuteStep={15}
          secondStep={10}
          style={{
            width: "100%",
            marginTop: "5px",
          }}
          disabled={!startTime}
          placeholder="Select End Time"
          disabledTime={() => {
            const selectedStartTimeHour = dayjs(startTime).hour();
            const selectedStartTimeMinutes = dayjs(startTime).minute();
            return {
              disabledHours: () => {
                const result = [];
                for (let i = 0; i < 24; i++) {
                  if (
                    i < selectedStartTimeHour ||
                    (i > 18 && i <= 24) ||
                    i == 0 ||
                    (i >= 1 && i < 6)
                  ) {
                    result.push(i);
                  }
                }
                return result;
              },
              disabledMinutes: (selectedHour) => {
                const result = [];
                for (let i = 0; i < 60; i++) {
                  if (
                    selectedHour <= selectedStartTimeHour &&
                    i <= selectedStartTimeMinutes
                  ) {
                    result.push(i);
                  }
                }
                return result;
              },
            };
          }}
          onSelect={(value) => {
            setErrors((prevErrors) => {
              return { ...prevErrors, endTime: "" };
            });
            setEndTime(value);
          }}
          value={endTime}
          format="HH:mm"
          use12Hours={true}
        />
        {errors?.endTime && (
          <Typography.Text style={{ color: "red", marginTop: "5px" }}>
            {errors?.endTime}
          </Typography.Text>
        )}
      </div>
      <div className="d-flex justify-content-end" style={{ marginTop: "10px" }}>
        <Button onClick={() => setShowUpdate(false)}>Cancel</Button>
        <Button
          type="primary"
          style={{ marginLeft: "10px" }}
          onClick={() => onUpdate()}
          loading={loading}
        >
          Update
        </Button>
      </div>
    </Drawer>
  );
};
export default UpdateTrainerSchedule;
