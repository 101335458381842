import { Modal, Button, Form, Input } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { sendOtp } from "../../pages/features/userSlice";
import ResetPasswordForm from "./ResetPasswordForm";

const ForgotPasswordComponent = ({
  setShowResetPwd,
  setShowForgotPassword,
  setShowSignup,
  setEmail,
}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [mail, setMail] = useState('');
  const onNext = async (data) => {
    
    console.log(data);
    setLoading(true);
    dispatch(
      sendOtp({
        values: data,
        onSuccess: () => {
          setMail(data.email);
          console.log(data.email);
          setShowForgotPassword(false);
          setShowResetPwd(true);
          setLoading(false);
          setShowResetPwd(true);
        },
        onFailure: () => {
          setLoading(false);
        },
      })
    );
  };
  return (
    <>
      <Modal
        open={true}
        className="forgot-password-modal"
        onCancel={() => setShowForgotPassword(false)}
        footer={null}
      >
        <div className="forgot-password-form">
          <div className="forgot-password-form">
            <h2>Forgot Password</h2>
            <Form onFinish={onNext}>
              <Form.Item
                name={"email"}
                rules={[{ required: true, message: "Email is required" }]}
              >
                <Input
                  type="email"
                  name="email"
                  size="large"
                  placeholder="Email address"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Item>
              <Button
                className="forget-password-btn"
                loading={loading}
                type="primary"
                htmlType="submit"
                size="large"
                block
              >
                Next
              </Button>
              <p className="text-center py-4">
                <label>
                  Click here to<Button
                    type="link" style={{padding:4}}
                    onClick={() => {
                      setShowForgotPassword(false);
                      setShowSignup(true);
                    }}
                  ><b>Sign Up</b>
                  </Button>
                </label>
              </p>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ForgotPasswordComponent;
