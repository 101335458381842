import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import "antd/dist/antd.css";
//import 'react-big-scheduler/node_modules/antd/dist/antd.css';
import { PlusCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import {
  getUser,
  scheduleTrainer,
  scheduleUser,
  userSelector,
} from "../../../pages/features/userSlice";
import Sidenav from "../../sidenav/sidenav";
import { Checkbox } from "react-big-scheduler/node_modules/antd/dist/antd";
import { useNavigate } from "react-router-dom";
import { Button, Typography } from "antd";
// import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Col, Row, DatePicker } from "antd";
import "./trainerScheduling.css";
import { TimePicker } from "antd";
import {
  ErrorNotification,
  FailureScheduleTrainerNotification,
  SuccessNotification,
  SuccessScheduleTrainerNotification,
} from "../../../pages/notifications/notification";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import customParseFormat from "dayjs/plugin/customParseFormat";
import moment from "moment";

const TrainerScheduling = () => {
  const [data, setData] = useState({
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
  });
  const [selectedWeek, setSelectedWeek] = useState();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const { isScheduleTrainerSuccess, isFetchUserSuccessResponse } =
    useSelector(userSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(getUser());
    // if (isScheduleTrainerSuccess) {
    //   SuccessNotification();
    //   navigate("/trainer-scheduling");
    // }
  }, []);
  dayjs.extend(weekday);
  dayjs.extend(customParseFormat);
  const onRemove = (index, week) => {
    setData((prevData) => {
      const currentWeekData = prevData?.[week];
      const currentWeekFinalData = currentWeekData?.filter(
        (item, currentIndex) => {
          return currentIndex !== index;
        }
      );
      return { ...prevData, [week]: currentWeekFinalData };
    });
  };

  const onAddTime = (week) => {
    setData({
      ...data,
      [week]: [...data?.[week], { startTime: "", endTime: "" }],
    });
  };

  const monCheck = (e, week) => {
    if (e.target.checked) {
      setData({
        ...data,
        [week]: [
          {
            startTime: "",
            endTime: "",
          },
        ],
      });
    } else {
      setData({ ...data, [week]: [] });
    }
  };
  const onSelectStartTime = (value, index, week) => {
    const validations = {};
    if (dayjs(value).hour() == 0) {
      validations["startTime"] = "Night time is not allowed";
    } else if (
      dayjs(value).hour() &&
      ((dayjs(value).hour() > 18 && dayjs(value).hour() <= 24) ||
        dayjs(value).hour() == 0 ||
        (dayjs(value).hour() >= 1 && dayjs(value).hour() < 8))
    ) {
      validations["startTime"] = "Night time is not allowed";
    }
    if (Object.keys(validations).length == 0) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          [week]: { ...prevErrors?.[week], [index]: "" },
        };
      });
      setData((prevData) => {
        const currentWeekData = prevData?.[week];
        const currentWeekFinalData = currentWeekData?.map(
          (item, currentIndex) => {
            if (currentIndex == index) {
              item["startTime"] = value;
            }
            return item;
          }
        );
        return { ...prevData, [week]: currentWeekFinalData };
      });
    } else {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          [week]: {
            ...prevErrors?.[week],
            [index]: validations["startTime"],
          },
        };
      });
    }
  };
  const onSelectEndTime = (value, index, week, startTime) => {
    const validations = {};
    console.log(value, index, week, startTime);
    if (startTime) {

      var start = moment(dayjs(startTime).hour()+':'+dayjs(startTime).minute(), 'HH:mm');
  var end   = moment(dayjs(value).hour()+':'+dayjs(value).minute(), 'HH:mm');
  var i=0;
//console.log(start)console.log('start'+moment(startTime)?.format("HH:mm"))
  while(start < end) {
    console.log('---------------')
    i=0;
   var endbefore= start.clone().add(30, 'minutes')
    if(endbefore<end)
    console.log('Start: ' + start.format( 'HH:mm'));
    start = start.clone().add(30, 'minutes')
    if(start<end){
     
      console.log('End:   ' + start.format( 'HH:mm'));
      console.log();
    }
    i++;
    console.log('---------------')
  }
 


      
      if (dayjs(value).hour() < dayjs(startTime).hour()) {
        validations["endTime"] = "End time should be greater than start time";
      } else if (
        dayjs(value).hour() > dayjs(startTime).hour() &&
        ((dayjs(value).hour() > 18 && dayjs(value).hour() <= 24) ||
          dayjs(value).hour() == 0 ||
          (dayjs(value).hour() >= 1 && dayjs(value).hour() < 8))
      ) {
        validations["endTime"] = "Night time is not allowed";
      } else if (
        dayjs(value).hour() == dayjs(startTime).hour() &&
        dayjs(value).minute() <= dayjs(startTime).minute()
      ) {
        validations["endTime"] = "End time should be greater than start time";
      }
    }
    if (Object.keys(validations).length == 0) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          [week]: { ...prevErrors?.[week], [index]: "" },
        };
      });
      setData((prevData) => {
        const currentWeekData = prevData?.[week];
        const currentWeekFinalData = currentWeekData?.map(
          (item, currentIndex) => {
            if (currentIndex == index) {
              item["endTime"] = value;
            }
            return item;
          }
        );
        return { ...prevData, [week]: currentWeekFinalData };
      });
    } else {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          [week]: { ...prevErrors?.[week], [index]: validations["endTime"] },
        };
      });
    }
  };
  const onSubmit = () => {
    setLoading(true);
    const currentData = JSON.parse(JSON.stringify(data));
    const finalData = Object.keys(currentData)?.reduce((acc, week) => {
      const currentWeekData = currentData?.[week];
      const currentWeekFinalData = currentWeekData?.filter((item) => {
        return item?.startTime && item?.endTime;
      });
      const formatStartAndEndTimes = currentWeekFinalData?.map((item) => {
        const finalStartTime = moment(item?.startTime)?.format("HH:mm");
        const finalEndTime = moment(item?.endTime)?.format("HH:mm");
        delete item["startTime"];
        delete item["endTime"];
        item["startTime"] = finalStartTime;
        item["endTime"] = finalEndTime;
        item["Status"] = true;
        return item;
      });
      acc = { ...acc, [week]: formatStartAndEndTimes };
      return acc;
    }, {});
    dispatch(
      scheduleTrainer({
        details: finalData,
        onSuccess: () => {
          setLoading(false);
          SuccessScheduleTrainerNotification();
          navigate("/trainer-schedules");
        },
        onFailure: () => {
          // console.log('error')
          setLoading(false);
          FailureScheduleTrainerNotification();
        },
      })
    );
  };

  return (
    <div>
    <section className="dashboard_info">
      <Row>
        <Col span={24}>
          <Typography.Title level={4} className="section-title">
          Schedule Inventory
          </Typography.Title>
          <div className="tab-content">
            <div id="home" className="container tab-pane active">
              {Object.keys(data)?.map((week) => {
                return (
                  <Row className="mb-2">
                    <Col span={9}>
                      <Checkbox
                        onChange={(e) => monCheck(e, week)}
                        checked={data?.[week]?.length > 0}
                      >
                        {" "}
                        {week}
                      </Checkbox>
                    </Col>
                    <Col span={14}>
                      {data?.[week]?.length == 0 ? (
                        <p>Unavailable</p>
                      ) : (
                        <>
                          {data?.[week].map((item, index) => {
                            return (
                              <>
                                <div
                                  key={item.id}
                                  className="d-flex align-items-center"
                                  style={{ marginTop: index !== 0 && "10px" }}
                                >
                                  <TimePicker
                                    minuteStep={15}
                                    secondStep={10}
                                    hideDisabledOptions
                                    style={{ width: "100%" }}
                                    placeholder="Select Start Time"
                                    onSelect={(value) => {
                                      onSelectStartTime(value, index, week);
                                      onSelectEndTime(null, index, week);
                                    }}
                                    value={item?.startTime}
                                    format="HH:mm"
                                    use12Hours={true}
                                    disabledTime={() => {
                                      return {
                                        disabledHours: () => {
                                          const result = [];
                                          for (let i = 0; i < 24; i++) {
                                            if (
                                              (i > 18 && i <= 24) ||
                                              i == 0 ||
                                              (i >= 1 && i < 8)
                                            ) {
                                              result.push(i);
                                            }
                                          }
                                          return result;
                                        },
                                      };
                                    }}
                                  />
                                  <TimePicker
                                    minuteStep={15}
                                    secondStep={10}
                                    style={{
                                      width: "100%",
                                      marginLeft: "10px",
                                    }}
                                    hideDisabledOptions
                                    disabled={!item?.startTime}
                                    placeholder="Select End Time"
                                    disabledTime={() => {
                                      const selectedStartTimeHour = dayjs(
                                        item?.startTime
                                      ).hour();
                                      const selectedStartTimeMinutes = dayjs(
                                        item?.startTime
                                      ).minute();
                                      return {
                                        disabledHours: () => {
                                          const result = [];
                                          for (let i = 0; i < 24; i++) {
                                            if (
                                              i < selectedStartTimeHour ||
                                              (i > 18 && i <= 24) ||
                                              i == 0 ||
                                              (i >= 1 && i < 6)
                                            ) {
                                              result.push(i);
                                            }
                                          }
                                          return result;
                                        },
                                        disabledMinutes: (selectedHour) => {
                                          const result = [];
                                          for (let i = 0; i < 60; i++) {
                                            if (
                                              selectedHour <=
                                                selectedStartTimeHour &&
                                              i <= selectedStartTimeMinutes
                                            ) {
                                              result.push(i);
                                            }
                                          }
                                          return result;
                                        },
                                      };
                                    }}
                                    onSelect={(value) => {
                                      onSelectEndTime(
                                        value,
                                        index,
                                        week,
                                        item?.startTime
                                      );
                                    }}
                                    value={item?.endTime}
                                    format="HH:mm"
                                    use12Hours={true}
                                  />
                                  {index == 0 ? (
                                    <PlusCircleTwoTone
                                      onClick={() => onAddTime(week)}
                                      style={{ marginLeft: "10px" }}
                                    />
                                  ) : (
                                    <CloseCircleTwoTone
                                      onClick={() => onRemove(index, week)}
                                      style={{ marginLeft: "10px" }}
                                    />
                                  )}
                                </div>
                                {errors?.[week]?.[index] && (
                                  <Typography.Text style={{ color: "red" }}>
                                    {errors?.[week]?.[index]}
                                  </Typography.Text>
                                )}
                              </>
                            );
                          })}
                        </>
                      )}
                    </Col>
                    <Col span={4}></Col>
                  </Row>
                );
              })}
              <Row>
                <Col span={2}></Col>
                <Col span={16}>
                  <Button
                    className="submitSchedule"
                    htmlType="submit"
                    type="primary"
                    onClick={() => onSubmit()}
                    loading={loading}
                  >
                    Submit Your Scheduling
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </section></div>
  );
};
export default TrainerScheduling;
