import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
// import "../home/login/login.css";
import "./header.scss";
import "./header.css";
import { useContext } from "react";
import { Context } from "../../pages/features/storeContext";
import {
  userSelector,
  loginUser,
  clearState,
  signUpUser,
  sendOtp,
  setLoginUser,
} from "../../pages/features/userSlice";
import {
  ErrorNotification,
  InfoNotification,
  SuccessNotification,SessionTimeoutInfoNotification
} from "../../pages/notifications/notification";
import LoginForm from "../home/login/LoginForm";
import SignupForm from "../home/signup/SignupForm";
import ForgotPasswordComponent from "./ForgotPasswordComponent";
import ForgotPassword from "./ForgotPassword";
import ResetPasswordForm from "./ResetPasswordForm";
import { Layout, Menu, Button, Popover } from "antd";
import logo from "../../assets/images/logo1.png";
import { UnorderedListOutlined } from "@ant-design/icons";

const { Header } = Layout;
const AVHeaderComponent = ({setIsLoggedin,setShowResetPwd,showResetPwd}) => {
  const [selectedKeys, setSelectedKeys] = useState(["1"]);
  const [role, setRole] = useState("");
  
  const [items, setItems] = useState([]);
  const userRole = localStorage.getItem("role");
  const [isShowLogin, setShowLogin] = React.useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const [showMenuPopover, setShowMenuPopover] = useState(false);
  const token = localStorage.getItem("userToken");
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => { 
    setShowResetPwd(setShowResetPwd);   
    setShowLogin(false); 
    if ((token && location.pathname == "/home") || location.pathname == "/") {
     
      navigate("/dashboard");
    }else   if ((location.pathname != "/dashboard" && location.pathname != "/home") && token===null) {
      localStorage.setItem("urlPath",location.pathname);
      navigate("/home"); 
      SessionTimeoutInfoNotification();
      setShowLogin(true);
    } 
    
  setRole(userRole);  
  if (userRole === "Trainer") {
    setItems(trainerItems);
  } else if (userRole === "Trainee") {
    setItems(traineeItems);
  }   
  if (
    location.pathname == "/trainer-scheduling" ||
    location.pathname == "/check-trainer-schedule"
  ) {
    setSelectedKeys(["2"]);
  } else if (
    location.pathname == "/trainer-schedules" ||
    location.pathname == "/trainee-schedules"
  ) {
    setSelectedKeys(["3"]);
  } else if (location.pathname == "/profile") {
    setSelectedKeys(["4"]);
  } else if (location.pathname == "/manage-password") {
    setSelectedKeys(["5"]);
  }
  }, []);

  if(location.pathname == "/dashboard" ) {
    setIsLoggedin(true)
  }else if( localStorage.getItem("urlPath")!=null && token!=null)
  {
    setIsLoggedin(true)
  }
  const onLogOut = () => {
 
    if(localStorage.getItem("email"))
    {
      const userdata=(localStorage.getItem("email"));    
    dispatch(setLoginUser({email:userdata,role:localStorage.getItem("role"),id:localStorage.getItem("id"),status:'offline'}))
    }
    localStorage.removeItem("userToken");
    localStorage.removeItem("email");
    sessionStorage.removeItem("role");
    localStorage.removeItem("role");
    localStorage.removeItem("id");
     localStorage.removeItem("urlPath");
    InfoNotification();
    window.location = "./home";
  };

  const [show, setShow] = useState();

  const mobileMenu = () => {
    if (show !== "undefined" && show !== "show") setShow("show");
    else setShow("");
  };
  // console.log(isShowLogin)
  const menuItems = [
    { label: "Home", key: "1", value: "home" },
    { label: "About", key: "2", value: "about" },
    { label: "Services", key: "3", value: "services" },
    { label: "Employment", key: "4", value: "employment" },
    { label: "Contact", key: "5", value: "contact" },
  ];
   
const trainerItems = [
  { label: "Home", value: "Home", key: "1" },
  {
    label: "Schedule Inventory",
    value: "book-session-availability",
    key: "2",
  }, 
  { label: "Booked Sessions", value: "my-schedules", key: "3" },
  { label: "Schedule Openings", value: "trainer-schedulesing", key: "7" },
  { label: "My Account", value: "my-account", key: "4" },
  { label: "Change Password", value: "manage-password", key: "5" },
  { label: "Logout", value: "logout", key: "6" },
];
const traineeItems = [
  { label: "Home", value: "Home", key: "1" },
  {
    label: "Schedule Training",
    value: "check-trainer-schedule",
    key: "2",
  },
  { label: "Booked Sessions", value: "trainee-schedules", key: "3" },
  { label: "My Account", value: "my-account", key: "4" },
  { label: "Change Password", value: "manage-password", key: "5" },
  { label: "Logout", value: "logout", key: "6" },
];
const onNavigate = (key) => { 
  setSelectedKeys(key);
  setShowMenuPopover(false);
  if (userRole == "Trainer") {
    if (key == "1") {
      navigate("/dashboard");
    } else if (key == "2") {
      navigate("/trainer-scheduling");
    } else if (key == "3") {
      navigate("/trainer-schedules");
    } else if (key == "4") {
      navigate("/profile");
    } else if (key == "5") {
      navigate("/manage-password");
    } else if (key == "6") {
      onLogOut();
    }  else if (key == "7") {
      navigate("/trainer-schedulesing");
    }
  } else {
    if (key == "1") {
      navigate("/dashboard");
    } else if (key == "2") {
      navigate("/check-trainer-schedule");
    } else if (key == "3") {
      navigate("/trainee-schedules");
    } else if (key == "4") {
      navigate("/profile");
    } else if (key == "5") {
      navigate("/manage-password");
    } else if (key == "6") {
      onLogOut();
    }
  }
};
  // console.log("header");
  const viewportWidth = document.documentElement.clientWidth;
  return (
    <>
      <Header
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          width: "100vw",
          backgroundColor: "white",
        }}
      >
        <div className="header-container">
          <img src={logo} alt="" width={150} height={100} />
          
          {viewportWidth < 768 ? (
            <Popover
              overlayClassName="menu-popover"
              placement="bottom"
              open={showMenuPopover}
              onOpenChange={(e) => setShowMenuPopover(e)}
              content={
                token === null ? (
                  <>
                    <Menu
                      defaultSelectedKeys={"1"}
                      items={menuItems}
                    />
                    <Button
                      type="primary"
                      shape="round"
                      size={"large"}
                      onClick={() => setShowSignup(true)}
                    >
                      Training
                    </Button>
                  </>
                ) : (
                  <>
                       <> {userRole === "Trainer" ?<>
                  <Menu   
                     selectedKeys={selectedKeys}
                    items={trainerItems}     onClick={(e) => {
                      setSelectedKeys([e.key]);
                      onNavigate(e.key);
                    }}
                  />
                </>:<>
                <Menu   
                     selectedKeys={selectedKeys}
                    items={traineeItems}     onClick={(e) => {
                      setSelectedKeys([e.key]);
                      onNavigate(e.key);
                    }}
                  />
                </>}
                
               
                </>
                    
                  </>
                )
              }
            >
              <UnorderedListOutlined className="toggle-btn" onClick={() => setShowMenuPopover(!showMenuPopover)}/>
            </Popover>
          ) : (
            <>
              {token === null ? (
                <>
                  <Menu style={{paddingLeft:230}}
                    mode="horizontal"
                    defaultSelectedKeys={"1"}
                    items={menuItems}
                  />
                  <Button
                    type="primary"
                    shape="round"
                    size={"large"}
                    onClick={() => setShowSignup(true)}
                  >
                    Training
                  </Button>
                </>
              ) : (
                <> {userRole === "Trainer" ?<>
                  <Menu  
                    mode="horizontal"   style={{width:850}}
                     selectedKeys={selectedKeys}
                    items={trainerItems}     onClick={(e) => {
                      setSelectedKeys([e.key]);
                      onNavigate(e.key);
                    }}
                  />
                </>:<>
                <Menu  
                    mode="horizontal"  style={{width:680}}
                     selectedKeys={selectedKeys}
                    items={traineeItems}     onClick={(e) => {
                      setSelectedKeys([e.key]);
                      onNavigate(e.key);
                    }}
                  />
                </>}
                
               
                </>
              )}
            </>
          )}
        </div> 
      </Header>
      {isShowLogin && (
        <LoginForm
          setShowLogin={setShowLogin}
          setShowForgotPassword={setShowForgotPassword}
          setShowSignup={setShowSignup}
        />
      )}
      {showSignup && (
        <SignupForm setShowSignup={setShowSignup} setShowLogin={setShowLogin} />
      )}
      {showForgotPassword && (
        <ForgotPassword
          setShowForgotPassword={setShowForgotPassword}   setShowResetPwd={setShowResetPwd}
          setShowSignup={setShowSignup}
        />
      )}
      {showResetPwd && (
        <ResetPasswordForm
          setShowResetPwd={setShowResetPwd}
          setShowSignup={setShowSignup}
        />
      )}
    </>
  );
};

export default AVHeaderComponent;
