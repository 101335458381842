import React, { useContext } from "react";
import { Context } from "../../pages/features/storeContext";
const AVBodyComponent = () => {
  const { form } = useContext(Context);
  console.log('body')
  return (
    <>
      <section>
        <div className="bg-video-wrap">
          <video
            src="https://avondale.pro/wp-content/uploads/2022/09/Old-Man-Walking.mp4"
            loop
            muted
            autoplay="true"
          ></video>
          <h1>Family Owned & Family Centered</h1>
          <h2 className="Sample">
            Contact us Today to Schedule a Consultation with one of our Trusted
            Advisors
          </h2>

          <div id="banner">
            <div className="col-md-5"></div>
          </div>
        </div>
      </section>
      <section className="about_info">
        <img src="images/about.png" />
      </section>
      <section className="about_info">
        <img src="images/services.png" />
      </section>
      <section className="about_info">
        <img src="images/senior.png" />
      </section>
      <section className="about_info">
        <img src="images/services2.png" />
      </section>
      <section className="about_info">
        <img src="images/img_bg.png" />
      </section>

      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Modal title
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="login_form">
                <input type="checkbox" id="form-switch" />
                <h2>Login & Register</h2>
                <p className="mb-3">
                  Coach with confidence again. Log in to your account to get
                  going.
                </p>
                <form id="login-form" action="" method="post">
                  <div className="mb-3">
                    <input
                      type="email"
                      placeholder="Email address"
                      className="form-control border border-primary"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="password"
                      placeholder="Password"
                      className="form-control border border-primary"
                      id="exampleInputPassword1"
                    />
                  </div>
                  <p className="small">
                    <a className="text-primary" href="#">
                      Forgot password?
                    </a>
                  </p>
                  <div className="sign_btn text-center">
                    <button className="btn btn-primary" type="submit">
                      Login
                    </button>
                  </div>
                  <p className="text-center py-4">
                    <label for="form-switch">
                      Don't have an account?
                      <span className="register">Register</span>
                    </label>
                  </p>
                </form>
                <form id="register-form" action="" method="post">
                  <div className="mb-3">
                    <input
                      type="email"
                      placeholder="First Name"
                      className="form-control border border-primary"
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="email"
                      placeholder="Phone Number"
                      className="form-control border border-primary"
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="email"
                      placeholder="Gender"
                      className="form-control border border-primary"
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="email"
                      placeholder="Email"
                      className="form-control border border-primary"
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="email"
                      placeholder="Password"
                      className="form-control border border-primary"
                    />
                  </div>
                  <div className="sign_btn text-center">
                    <button className="btn btn-primary" type="submit">
                      Register
                    </button>
                  </div>
                  <p className="text-center py-4">
                    <label for="form-switch">
                      Already Member ?{" "}
                      <span className="register">Login Now..</span>
                    </label>
                  </p>
                </form>
              </div>
            </div>
            {/* <div className="modal-footer">
        <a type="button" className="btn btn-secondary" data-dismiss="modal">Close</a>
        <button type="button" className="btn btn-primary">Save changes</button>
      </div> */}
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Modal title
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="login_form">
                <input type="checkbox" id="form-switch" />
                <h2>Login & Register</h2>
                <p className="mb-3">
                  Coach with confidence again. Log in to your account to get
                  going.
                </p>
                <form id="login-form" action="" method="post">
                  <div className="mb-3">
                    <input
                      type="email"
                      placeholder="Email address"
                      className="form-control border border-primary"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="password"
                      placeholder="Password"
                      className="form-control border border-primary"
                      id="exampleInputPassword1"
                    />
                  </div>
                  <p className="small">
                    <a className="text-primary" href="#">
                      Forgot password?
                    </a>
                  </p>
                  <div className="sign_btn text-center">
                    <button className="btn btn-primary" type="submit">
                      Login
                    </button>
                  </div>
                  <p className="text-center py-4">
                    <label for="form-switch">
                      Don't have an account?
                      <span className="register">Register</span>
                    </label>
                  </p>
                </form>
                <form id="register-form" action="" method="post">
                  <div className="mb-3">
                    <input
                      type="email"
                      placeholder="First Name"
                      className="form-control border border-primary"
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="email"
                      placeholder="Phone Number"
                      className="form-control border border-primary"
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="email"
                      placeholder="Gender"
                      className="form-control border border-primary"
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="email"
                      placeholder="Email"
                      className="form-control border border-primary"
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="email"
                      placeholder="Password"
                      className="form-control border border-primary"
                    />
                  </div>
                  <div className="sign_btn text-center">
                    <button className="btn btn-primary" type="submit">
                      Register
                    </button>
                  </div>
                  <p className="text-center py-4">
                    <label for="form-switch">
                      Already Member ?{" "}
                      <span className="register">Login Now..</span>
                    </label>
                  </p>
                </form>
              </div>
            </div>
            {/* <div className="modal-footer">
        <a type="button" className="btn btn-secondary" data-dismiss="modal">Close</a>
        <button type="button" className="btn btn-primary">Save changes</button>
      </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AVBodyComponent;
