import { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Navigate } from 'react-router-dom';
// import { userSelector, loginUser, clearState, signUpUser } from '../../features/userSlice';
import { TailSpin } from 'react-loader-spinner'
import './home.css'
import Icons from "../../assets/images/icon2.png";
import Icon1 from "../../assets/images/icon1.png";
import Howits from "../../assets/images/howits.png";
import Search from "../../assets/images/search.png";
import Video from "../../assets/images/video.png";
import Improve from "../../assets/images/improve.png";
import { useContext } from "react";
import { Context } from "../../pages/features/storeContext";
import Login from "./login/login";
import Signup from "./signup/signup";
import { toast } from "react-toastify";

const Home = () => {
	const { form } = useContext(Context)

	const token = localStorage.getItem('userToken')
	if (token !== null) {
		return <Navigate to="/" />
	}

	return (
		<div className="home">
			<section id="banner">
				<div className="row">
					<div className="col-md-8"></div>
				 
				</div>
			</section>
			<section className="about_info">
				<div className="row">
					<div className="col-md-4">
						<h3>The NetQwix Golf Club</h3>
					</div>
					<div className="col-md-8">
						<p>
							At NetQwix, Professionals can teach LIVE through a customized interactive video conferencing platform (IVCP) built specifically for distance learning. Users can instantly take a live streaming interactive session with an expert in their sport or activity of choice (or a lesson can be pre-scheduled.)
						</p>
					</div>
				</div>
			</section>
			<section className="works">
				<h3 className="text-center">HOW IT WORKS</h3>
				<div className="row">
					<div className="col-md-4">
						<img src={Search} />
						<h4>Find Your Coach</h4>
						<p>Share your challenges and goals, and we’ll recommend great coaches for you.</p>
					</div>
					<div className="col-md-4">
						<img src={Video} />
						<h4>Upload Video</h4>
						<p>Explain your weaknesses and questions, and follow your coach’s instructions to upload footage of your motion.</p>
					</div>
					<div className="col-md-4">
						<img src={Improve} />
						<h4>Improve!</h4>
						<p>Your coach uses Skillest's tools to diagnose, create a detailed analysis, attach drills and guide you as often as you need *</p>
					</div>
				</div>
			</section>
			<section className="findgame_info">
				<div className="row">
					<div className="col-md-6 howfind">
						<img src={Howits} />
					</div>
					<div className="col-md-6 gameinfo">
						<h4>FIND YOUR GAME</h4>
						<p>As a full time coach Chris has conducted over 10,000 lessons ranging from the complete novice to players in the professional ranks.</p>
						<p>Chris’s coaching methods have helped golfers of all levels both play better, and understand some of the key conecpts needed to do this.</p>
						<p>To begin please select the coaching method that best suits you:</p>
						<div className="row coursebtn">
							<div className="col-md-6">
								<h6>I need a specific course:</h6>
								<a href="#"><img src={Icons} />DIGITAL COURSES</a>
							</div>
							<div className="col-md-6">
								<h6>I need online coaching:</h6>
								<a href="#"><img src={Icon1} />ONLINE COACHING</a>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	)
}
export default Home