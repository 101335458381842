import React, { Fragment, useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    userSelector,
    getUser,
    clearState
  } from "../../pages/features/userSlice";
  import {
    
    getScheduledDetailsByTrainerId,
   
  } from "../../pages/features/userSlice";
import moment from 'moment';
const TraineeScheduleTable = () => {
  
    const { isFetchUserSuccess, isFetchUserSuccessResponse } =
    useSelector(userSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const hrefValue = (value) =>{
    return "https://pocdemo.aitacs.com/"+value+"#userInfo.displayName="+isFetchUserSuccessResponse[0].full_name;
  }

 
  const {
    
    TraineeBookedSchedules
  } = useSelector(userSelector);
  
const dayFormat=(eventDate)=>
{
  const date1=  new moment(eventDate).format("MMM Do, YYYY");
  return date1;
}

const bookedStatus = (value) => {
  if(value=='B')
  {
    return 'Booked'
  }else   if(value=='C')
  {
    return 'Confirmed'
  }else   if(value=='D')
  {
    return 'Cancel'
  }else return '';

    // return value.replace(/^(?:00:)?0?/, '').splice(:2);
    // return new Date().toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'});
  };
  useEffect(() => {
   // getData();
    // dispatch(getUser());
  
    // dispatch(getScheduledDetailsByTrainerId());
 },[])

 const dateFormat = (value) => {
  value = value.replace(/:[^:]*$/, "");

  value = value.replace(/^0+/, "");
  const hourMin = value.split(":");
  var hour = hourMin[0];
  var min = hourMin[1];

  var AmOrPm = hour >= 12 ? " pm" : " am";
  hour = hour % 12 || 12;
  //var minutes = dt.getMinutes() ;

  value = hour + ":" + min + "" + AmOrPm;

 // console.log(value);

  // return value.replace(/^0+/, '')
  return value;
};

const sessionDuration = (start_time,end_time) => {
   

  var scheduled_date=new Date();
  scheduled_date.setHours(parseInt(start_time.split(':')[0]));
  scheduled_date.setMinutes(parseInt(start_time.split(':')[1])); 
  var start = new moment(new Date(scheduled_date)).format("HH:mm ");

  scheduled_date.setHours(parseInt(end_time.split(':')[0]));
  scheduled_date.setMinutes(parseInt(end_time.split(':')[1]));
  var end =new moment(new Date(scheduled_date)).format("HH:mm ");

  var startTime = moment(start, "HH:mm");
  var endTime = moment(end, "HH:mm");
  var sessionDuration = endTime.diff(startTime, "minutes");
 // console.log('sessionDuration'+sessionDuration);
  return sessionDuration;
};
 console.log(isFetchUserSuccessResponse);
 //console.log(TraineeBookedSchedules);
  
 return (
    <div>
       <div >
            <div class="col-lg-12 col-md-10 col-sm-13">
              <h5 class="mt-3 mb-3 text-secondary"      style={{ fontWeight: "bold", fontSize: "18px" }}>
                
               Scheduled Training Sessions
              </h5>
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead class="thead-light">
                            <tr>
                               
                                <th>Trainee</th>
                                <th>Date</th>
                                <th>Start - End Time</th>
                                <th>Duration</th>
                                <th>Status</th>  
                               
                            </tr>
                        </thead>
                        <tbody>
                         {TraineeBookedSchedules.data?.slice(0, 15).map((data)=>
                            <tr>
                                <td>{data.full_name}</td>
                                
                                <td>{dayFormat(data.scheduled_date)}</td>
                                {  <td>{dateFormat(data.start_time)} -{dateFormat(data.end_time)}</td> }
                                <td> {sessionDuration(data.start_time,data.end_time)} min</td> 
                                <td>{bookedStatus(data.status)}</td> 
                                <td>{data.meetingcode?<><a href={hrefValue(data.meetingcode)} target="_blank">Start </a></>:<></>}</td> 
                                <td></td>
                            </tr>
                           )}
                        </tbody>
                    </table>
                </div>
            </div>
            {/* <div className="col-lg-5 col-md-6 col-sm-12 col-sm-offset-5">
                <h4 className='title mt-3 mb-3 text-center text-secondary'>Data in Chart</h4>
                   <div className="mb-5" style={{height:"300px",width:"400px"}}><TraineeScheduleTable/> </div></div>   */}
        </div>

    </div>
  )
}

export default TraineeScheduleTable