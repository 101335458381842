import { Modal, Typography, Form, Input, Button } from "antd";
import { useState } from "react";
import "./header.scss";

const ForgotPasswordOtp = ({
  email,
  setShowOtpForm,
  setShowForgotPassword,
}) => {
  const [loading, setLoading] = useState(false);
  const onSubmit = (data) => {
    console.log(data);
  };
  return (
    <Modal
      open={true}
      className="login-modal"
      footer={null}
      onCancel={() => {
        setShowOtpForm(false);
        setShowForgotPassword(false);
      }}
    >
      <div className="login_form mt-4">
        <Typography.Text className="otp-title">
          Enter the Otp sent to the email {email}
        </Typography.Text>
        <Form onFinish={onSubmit} className="opt-submit-form">
          <Form.Item name={"otp"}>
            <Input name="otp" placeholder="otp" />
          </Form.Item>
          <Button
            className="forget-password-btn"
            loading={loading}
            type="primary"
            htmlType="submit"
            block
          >
            Next
          </Button>
        </Form>
      </div>
    </Modal>
  );
};
export default ForgotPasswordOtp;
