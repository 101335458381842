import { Modal, Form, Input, Select, Button, Typography } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  clearState,
  signUpUser,
  verifyEmail,
} from "../../../pages/features/userSlice";

import "./signup.scss";
import { useNavigate } from "react-router-dom";

const SignupForm = ({ setShowSignup, setShowLogin }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    setLoading(true);
    dispatch(
      signUpUser({
        values: data,
        onSuccess: () => {
          console.log("success");
          setLoading(false);
          setShowSignup(false);
          navigate("/dashboard");
        },
        onFailure: () => {
          setLoading(false);
        },
      })
    );
    dispatch(clearState());
  };
  const verifyEmailRequest = (email) => {
    dispatch(
      verifyEmail({
        email: email,
        onSuccess: (response) => {
          if (response?.message !== "No user found") {
            setError(response?.message);
          }
        },
        onFailure: (error) => {
          console.log(error);
        },
      })
    );
  };
  const [form] = Form.useForm();
  console.log(form);
  return (
    <Modal
      open={true}
      className="signup-modal"
      onCancel={() => setShowSignup(false)}
      footer={null}
    >
      <div className="signup_form">
        <div>
          <h2>NetQwix Training Portal</h2>
          <p className="mb-3">Sign up to schedule your Training Session</p>
          <Form onFinish={onSubmit} form={form}>
            <Form.Item
              name="full_name"
              rules={[
                { required: true, message: "Full name is required" },
                {
                  min: 4,
                  message: "Full name must be at least 4 characters long",
                },
                {
                  pattern: new RegExp(
                    /(?=[A-Za-z_ ]+$)^(?=.{4,}).*$/
                  ),
                  message: "Full name should contain at least 4 characters length",
                },
              ]}
            >
              <Input
                type="text"
                name="full_name"
                placeholder="Full Name"
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: "Email is required" },
                { whitespace: true },
                {
                  pattern: new RegExp(
                    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
                  ),
                  message: "Email is not valid",
                },
              ]}
            >
              <Input
                type="email"
                name="email"
                size="large"
                placeholder="Email"
                onBlur={(e) => verifyEmailRequest(e.target.value)}
                onChange={(e) => {
                  setError("");
                  form.setFieldValue("email", e.target.value);
                }}
              />
            </Form.Item>
            {error && (
              <Typography.Text style={{ color: "red" }}>
                {error}
              </Typography.Text>
            )}
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Password is required" },
                {
                  pattern: new RegExp(
                    /(?=[A-Za-z0-9!@#\$%\^\&*\)\(+=._-]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^\&*\)\(+=._-])(?=.{8,}).*$/
                  ),
                  message: "Password should contain at least 1 upper case, at least 1 special character, 1 lower and 1 number with 8 characters length",
                },
              ]}
            >
              <Input.Password
                type="password"
                name="password"
                placeholder="Password"
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="phone_number"
              rules={[
                { required: true, message: "Phone number is required" },
                {
                  len: 10,
                  message: "Enter valid phone number",
                },
                {
                  pattern: new RegExp(/(?=[0-9]+$)^[1-9].*$/),
                  message: "Phone number should start from 1 to 9",
                },
              ]}
            >
              <Input
                type="text"
                name="phone_number"
                placeholder="(xxx) xxx-xxxx"
                maxLength={10}
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="role"
              rules={[{ required: true, message: "Please Choose Account Type" }]}
            >
              <Select
                placeholder="Choose Account Type"
                options={[
                  { label: "Trainee", value: "Trainee" },
                  { label: "Trainer", value: "Trainer" },
                ]}
                size="large"
              />
            </Form.Item>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              block
              loading={loading}
            >
              Sign Up
            </Button>
            <p className="text-center py-4">
              <label>
                Already have an account?{" "}
                <Button
                  type="link"
                  onClick={() => {
                    setShowSignup(false);
                    setShowLogin(true);
                  }} className="signup_form"
                ><b>
                  Sign in</b>
                </Button>
              </label>
            </p>
          </Form>
        </div>
      </div>
    </Modal>
  );
};
export default SignupForm;
