import { Modal, Form, Input, Button } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { clearState, loginUser } from "../../../pages/features/userSlice";
import "./login.scss";
import { useNavigate } from "react-router-dom";

const LoginForm = ({ setShowLogin, setShowForgotPassword,setShowResetPwd, setShowSignup }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSubmit = async (data) => {
    setLoading(true);
    dispatch(
      loginUser({
        values: data,
        onSuccess: () => {
          console.log('success');
          setLoading(false);
          setShowLogin(false)
         // console.log(localStorage.getItem("urlPath"));
          
          if(localStorage.getItem("urlPath")!=null)
          {
            navigate(localStorage.getItem("urlPath"));
          }else 
          navigate("/dashboard");
        },
        onFailure: () => {
          setLoading(false);
        },
      })
    );
    dispatch(clearState());
  };
  return (
    <Modal
      open={true}
      onCancel={() => setShowLogin(false)}
      className="login-modal"
      footer={null}
    >
      <div className="login_form">
        <div className="login_form" id="login-form">
          <h2>NetQwix Training Portal Login</h2>
          <p className="mb-3">&nbsp;
            {/* Coach with confidence again. Sign in to your account to get going. */}
          </p>
          <Form onFinish={handleSubmit}>
            <Form.Item
              name={"email"}
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input
                type="email"
                name="email"
                placeholder="Email address"
              />
            </Form.Item>

            <Form.Item
              name={"password"}
              className="password-input"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input
                type="password"
                name="password"
                placeholder="Password"
              />
            </Form.Item>
            <Button
              type="link"
              className="forgot-password-btn"
              onClick={() => {
                setShowLogin(false)
                setShowForgotPassword(true)
              }}
            >
              Forgot password?
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              block
              loading={loading}
              size="large"
            >
              Sign in
            </Button>
            <p className="text-center py-4">
              <label>
                {/* Sign up to schedule your Training Session. */}
                  Click here to<Button
                  type="link"
                  onClick={() => {
                    setShowLogin(false);
                    setShowSignup(true);
                  }}      className="login_form"
                >
                  <b>Sign Up</b>
                </Button>
              </label>
            </p>
          </Form>
        </div>
      </div>
    </Modal>
  );
};
export default LoginForm;
