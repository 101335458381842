import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Tabs, Typography, Row, Col } from "antd";
import Sidenav from "../../sidenav/sidenav";
import { userSelector } from "../../../pages/features/userSlice";
import TrainerSchedules from "../trainerSchedules/trainerSchedules";
import TraineeBookedSchedules from "../traineeBookedSchedules/traineeBookedSchedules"

const Schedules = () => {
  const { TabPane } = Tabs;
  const { isFetchUserSuccessResponse } = useSelector(userSelector);

  return (
    <div>
      <section className="dashboard_info">
        <Row>
          {/* <Sidenav props={isFetchUserSuccessResponse} /> */}
          <Col span={23}>
            <Typography.Title level={4} className="section-title">Booking Summary</Typography.Title>
      
            <TraineeBookedSchedules />     {/* <div className="card-container">
              <Tabs type="card" defaultActiveKey="1">
                <TabPane tab="Schedule Openings" key="2">
                  <TrainerSchedules />
                </TabPane>
                <TabPane tab="Booked Sessions" key="1">
                  <TraineeBookedSchedules />
                </TabPane>
              </Tabs>
            </div> */}
          </Col>
        </Row>
      </section>
    </div>
  );
};
export default Schedules;