import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearState,
  getProfileDetails,
  updateTrainerProfile,
  uploadProfile,
  uploadTrainerProfile,
  userSelector,
} from "../../../pages/features/userSlice";
import Sidenav from "../../sidenav/sidenav";
import { InputNumber, Space, Input, Button, Typography } from "antd";
import { Modal, Divider, Col, Row, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import { toast } from "react-toastify";
import {
  SuccessBookedSlotNotification,
  SuccessNotification,
  SuccessUpdatedProfileNotification,
  SuccessUploadedProfileNotification,
} from "../../../pages/notifications/notification";
import { useNavigate } from "react-router-dom";
import { Alert } from "antd";
import "./trainerProfile.css";
import { BsFillGeoAltFill } from "react-icons/bs";

const { TextArea } = Input;

const TrainerProfile = () => {
  const {
    isFetching,
    isSuccessuploadTrainerProfile,
    isError,
    errorMessage,
    isFetchUserSuccessResponse,
    getProfileDetailsResponse,
    updateTrainerProfileisSuccess,
  } = useSelector(userSelector);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState({
    location: "",
    price: "",
    description: "",
  });
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [price, setPrice] = useState("");
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [openModal, setOpenModel] = useState(false);
  const [file, setFile] = useState(null);
  const [fileList, setFileList] = useState([]);

  const handlePriceChange = (value) => {
    setPrice(value);
  };

  const handleChange = ({ currentTarget: input }) => {
    setData({ ...data, [input.name]: input.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("location", data.location);
    formdata.append("description", data.description);
    formdata.append("price", price);
    formdata.append("file", file);
    dispatch(uploadTrainerProfile(formdata));
  };

  const settingModal = (data) => {
    setFullName(getProfileDetailsResponse?.data?.[0]?.full_name);
    setEmail(getProfileDetailsResponse?.data?.[0]?.email);
    setRole(getProfileDetailsResponse?.data?.[0]?.role);
    setPhoneNumber(getProfileDetailsResponse?.data?.[0]?.phone_number);
    setPrice(getProfileDetailsResponse?.data?.[0]?.price);
    setLocation(getProfileDetailsResponse?.data?.[0]?.location);
    setDescription(getProfileDetailsResponse?.data?.[0]?.description);
    setOpenModel(true);
  };
  const handleOk = () => {
    setOpenModel(false);
  };

  const handleCancel = () => {
    setOpenModel(false);
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("fullName", fullName);
    formdata.append("email", email);
    formdata.append("role", role);
    formdata.append("phoneNumber", phoneNumber);
    formdata.append("location", location);
    formdata.append("description", description);
    formdata.append("price", price);
    formdata.append("file", file);
    dispatch(
      updateTrainerProfile({
        details: formdata,
        onSuccess: () => {
          setOpenModel(false);
          SuccessUpdatedProfileNotification();
          dispatch(clearState());
        },
      })
    );
  };

  useEffect(() => {
    dispatch(getProfileDetails());
  }, [updateTrainerProfileisSuccess]);

  useEffect(() => {
    if (isSuccessuploadTrainerProfile) {
      SuccessUploadedProfileNotification();
      dispatch(clearState());
    }
  }, [isSuccessuploadTrainerProfile]);

  const numberFormat = (value) => {
    return value.replace(/^(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  };
  // console.log(getProfileDetailsResponse, fileList);
  return (
    <div>
      <section className="dashboard_info">
        <Row>
          <Col span={23}>
            <Typography.Title level={4} className="section-title">
              My Account
            </Typography.Title>
            {/* {getProfileDetailsResponse?.data?.length > 0 ? (
              ""
            ) : isFetchUserSuccessResponse?.[0]?.role === "Trainer" ? (
              <Alert
                message="Warning"
                description="Please Update your profile then only you can able to scheduling"
                type="warning"
                showIcon
                closable
              />
            ) : (
              <Alert
                message="Warning"
                description="Please Update your profile then only you can able to book the schedular"
                type="warning"
                showIcon
                closable
              />
            )} */}
            <div className="tab-content">
              <div id="home" className="container tab-pane active">
                {getProfileDetailsResponse?.data?.length > 0 ? (
                  <div>
                    <Modal  style={{marginTop:10}}
                      title="Update User Profile"
                      open={openModal}
                      centered
                      onOk={handleOk}
                      onCancel={handleCancel}
                      footer={null}
                      width={700}
                    >
                      <div>
                        <div className="row">
                          <form method="post" onSubmit={handleUpdateSubmit}>
                            <Row className="rowstyle">
                              <Col span={6}>Full Name</Col>
                              <Col span={18}>
                                <input
                                  type="text"
                                  name="fullName"
                                  onChange={(e) => setFullName(e.target.value)}
                                  value={fullName}
                                  placeholder="Full Name"
                                  className="form-control border border-primary"
                                />
                              </Col>
                            </Row>
                            <Row className="rowstyle">
                              <Col span={6}>Email</Col>{" "}
                              <Col span={18}>
                                <input
                                  type="email"
                                  name="email"
                                  onChange={(e) => setEmail(e.target.value)}
                                  value={email}
                                  placeholder="Email"
                                  className="form-control border border-primary"
                                />
                              </Col>
                            </Row>
                            <Row className="rowstyle">
                              <Col span={6}>Role</Col>{" "}
                              <Col span={18}>
                                <select
                                  className="form-select"
                                  aria-label="Default select example"
                                  name="role"
                                  onChange={(e) => setRole(e.target.value)}
                                  value={role}
                                >
                                  <option value="" disabled selected>
                                    Sign up For
                                  </option>
                                  <option value="Trainee">Trainee</option>
                                  <option value="Trainer">Trainer</option>
                                </select>
                              </Col>
                            </Row>
                            <Row className="rowstyle">
                              <Col span={6}>Phone Number</Col>{" "}
                              <Col span={18}>
                                <input
                                  type="text"
                                  name="phoneNumber"
                                  placeholder="(xxx) xxx-xxxx"
                                  onChange={(e) =>
                                    setPhoneNumber(e.target.value)
                                  }
                                  value={phoneNumber}
                                  className="form-control border border-primary"
                                />
                              </Col>
                            </Row>{" "}
                            <Row className="rowstyle">
                              <Col span={6}>Hometown</Col>{" "}
                              <Col span={18}>
                                <input
                                  type="text"
                                  name="location"
                                  placeholder="Location"
                                  onChange={(e) => setLocation(e.target.value)}
                                  value={location}
                                  className="form-control border border-primary"
                                />
                              </Col>
                            </Row>
                            {getProfileDetailsResponse?.data?.[0]?.role ==
                            "Trainer" ? (
                              <></>
                            ) : (
                              ""
                            )}
                            <Row className="rowstyle">
                              <Col span={6}>Profile</Col>{" "}
                              <Col span={18}>
                                <TextArea
                                  rows={4}
                                  name="description"
                                  placeholder="description"
                                  allowClear
                                  onChange={(e) =>
                                    setDescription(e.target.value)
                                  }
                                  value={description}
                                />
                              </Col>
                            </Row>
                            <Row className="rowstyle">
                              <Col span={6}>
                                Click here to upload a link or file
                              </Col>{" "}
                              <Col span={18}>
                                <ImgCrop rotate={false}>
                                  <Upload
                                    action={null}
                                    customRequest={null}
                                    listType="picture-card"
                                    fileList={fileList}
                                    maxCount={1}
                                    onRemove={() => {
                                      setFile(null);
                                      setFileList([]);
                                    }}
                                    beforeUpload={(file, fileList) => {
                                      setFile(file);
                                      const url = URL.createObjectURL(file);
                                      setFileList([
                                        { ...fileList?.[0], url: url },
                                      ]);
                                      return false;
                                    }}
                                  >
                                    Upload
                                  </Upload>
                                </ImgCrop>
                                {/* <input
                                  type="file"
                                  name="picture"
                                  placeholder="picture"
                                  className="form-control border border-primary"
                                  onChange={handlePicChange}
                                /> */}
                              </Col>
                            </Row>
                            <Row>
                              <Col span={6} style={{ marginLeft: "500px" }}>
                                <div
                                // style={{
                                //   justifyContent: "end",
                                //   display: "flex",
                                // }}
                                >
                                  <button
                                    className="btn btn-primary"
                                    type="submit"
                                  >
                                    Update
                                  </button>
                                  <button
                                    className="btn btn-danger"
                                    style={{ marginLeft: "5px" }}
                                    onClick={handleCancel}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </form>
                        </div>
                      </div>
                    </Modal>

                    <Row className="rowstyle">
                      <Col span={23}>
                        {getProfileDetailsResponse?.data?.[0]?.file_name ? (
                          <>
                            <img
                              width={250}
                              height={150}
                              src={`http://netqwix.aitacs.com:5200/uploadedFile/${getProfileDetailsResponse?.data?.[0]?.file_name}`}
                            />{" "}
                          </>
                        ) : (
                          <> </>
                        )}
                      </Col>
                      <Col span={4}>
                        <Button
                          className="updateProfile"
                          type="primary"  style={{marginTop:10}}
                          onClick={() =>
                            settingModal(getProfileDetailsResponse)
                          }
                        >
                          Update Your Profile
                        </Button>
                      </Col>
                    </Row>
                    <Row className="rowstyle">
                      <Col span={4}>
                        <b>Full Name </b>
                      </Col>
                      <Col span={10}>
                        <p>
                          {" "}
                          {getProfileDetailsResponse?.data?.[0]?.full_name}
                        </p>
                      </Col>
                    </Row>
                    <Row className="rowstyle">
                      <Col span={4}>
                        <b>Email </b>
                      </Col>
                      <Col span={10}>
                        <p> {getProfileDetailsResponse?.data?.[0]?.email}</p>
                      </Col>
                    </Row>
                    <Row className="rowstyle">
                      <Col span={4}>
                        <b>Phone Number</b>
                      </Col>
                      <Col span={10}>
                        <p>
                          {numberFormat(
                            getProfileDetailsResponse?.data?.[0]?.phone_number
                          )}
                        </p>
                      </Col>
                    </Row>
                    {/* <Row className="rowstyle">
                      <Col sm={3}>
                        <b>Role</b>
                      </Col>
                      <Col sm={6}>
                        <p> {getProfileDetailsResponse?.data?.[0]?.role}</p>
                      </Col>
                    </Row> */}
                    <Row className="rowstyle">
                      <Col span={4}>
                        <b>Profile </b>
                      </Col>
                      <Col span={10}>
                        <p>
                          {" "}
                          {getProfileDetailsResponse?.data?.[0]?.description}
                        </p>
                      </Col>
                    </Row>
                    <Row className="rowstyle">
                      <Col span={4}>
                        <b>Hometown</b>
                      </Col> 
                      <Col span={10}>
                        <p> {getProfileDetailsResponse?.data?.[0]?.location}</p>
                      </Col>
                    </Row>
                    {getProfileDetailsResponse?.data?.[0]?.role == "Trainer" ? (
                      <></>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <form method="post">
                    <Row className="rowstyle">
                      <Col span={6}>
                        <b>
                          <h4 style={{ color: "navy-blue" }}>
                            Hometown
                            <BsFillGeoAltFill />{" "}
                          </h4>{" "}
                        </b>
                      </Col>
                      <Col span={18}>
                        <Input
                          name="location"
                          placeholder="Location"
                          onChange={handleChange}
                          value={data.location}
                        />
                      </Col>
                    </Row>
                    {isFetchUserSuccessResponse?.[0]?.role === "Trainer" && (
                      <></>
                    )}
                    <Row className="rowstyle">
                      <Col span={6}>
                        <b>
                          <h4 style={{ color: "navy-blue" }}>Profile</h4>
                        </b>
                      </Col>
                      <Col span={18}>
                        <TextArea
                          rows={4}
                          name="description"
                          placeholder="description"
                          allowClear
                          onChange={handleChange}
                          value={data.description}
                        />
                      </Col>
                    </Row>

                    <Row className="rowstyle">
                      <Col span={6}>
                        <b>
                          {" "}
                          <h4>Click here to upload a link or file </h4>
                        </b>
                      </Col>
                      {/* <input
                        type="file"
                        name="picture"
                        placeholder="picture"
                        className="form-control border border-primary"
                        onChange={handlePicChange}
                      /> */}
                      <Col span={18}>
                        <ImgCrop rotate={false}>
                          <Upload
                            action={null}
                            customRequest={null}
                            listType="picture-card"
                            fileList={fileList}
                            maxCount={1}
                            onRemove={() => {
                              setFile(null);
                              setFileList([]);
                            }}
                            beforeUpload={(file, fileList) => {
                              setFile(file);
                              const url = URL.createObjectURL(file);
                              setFileList([{ ...fileList?.[0], url: url }]);
                              return false;
                            }}
                          >
                            Upload
                          </Upload>
                        </ImgCrop>
                      </Col>
                    </Row>

                    {/* <div className="sign_btn text-center"> */}
                    <Button type="primary" block onClick={handleSubmit}>
                      Submit
                    </Button>
                    {/* </div> */}
                  </form>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </section>
    </div>
  );
};
export default TrainerProfile;
