import { Button, Form, Input, Row, Col, Card,Typography } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  managePassword,
  loginUser,
  userSelector,
} from "../../../pages/features/userSlice";
import { FailureUpdatePasswordNotification, SuccessUpdatePasswordNotification } from "../../../pages/notifications/notification";
import "./trainerProfile.css"; 

const ManagePassword = () => {
  const { getProfileDetailsResponse } = useSelector(userSelector);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSubmit = (data) => {
    // console.log(data);
    setLoading(true);
    dispatch(
      managePassword({
        values: data,
        onSuccess: () => {
          setLoading(false);
          SuccessUpdatePasswordNotification()
          navigate('/dashboard')
        },
        onFailure: () => {
          FailureUpdatePasswordNotification()
          setLoading(false);
        },
      })
    );
  };
  return (
    <div>
      <section className="dashboard_info">
      <Row>
          <Col span={23}>
            <Typography.Title level={4} className="section-title">
             Change Password
            </Typography.Title>
            </Col></Row>   <div className="row">
          <div className="col-md-2">&nbsp;</div></div> <div className="row">
          <div className="col-md-2">&nbsp;</div></div>
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <Card title="Change Password" className="manage-password">
              <Form layout="vertical" onFinish={onSubmit}>
                <Form.Item
                  name="oldPassword"
                  label="Old password"
                  rules={[
                    {
                      required: true,
                      message: "Password is required",
                    },
                  ]}
                >
                  <Input.Password
                    name="password"
                    placeholder="Enter old password"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  label="New password"
                  rules={[
                    { required: true, message: "Password is required" },
                    {
                      pattern: new RegExp(
                        /(?=[A-Za-z0-9]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,}).*$/
                      ),
                      message:
                        "Passowrd should contain atleast 1 upper case, 1 lower and 1 number with 8 characters length",
                    },
                  ]}
                >
                  <Input.Password
                    name="password"
                    placeholder="Enter new password"
                  />
                </Form.Item>
                <Form.Item
                  name="confirmPassword"
                  label="Confirm Password"
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The two passwords that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder="Confirm your password" />
                </Form.Item>
                <Button
                  htmlType="submit"
                  size="large"
                  type="primary"
                  block
                  loading={loading}
                >
                  Submit
                </Button>
              </Form>
            </Card>
          </div>
        </div>
      </section>
    </div>
  );
};
export default ManagePassword;
