import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Dashboard from "./components/dashboard/dashboard";
import FooterComponent from "./components/footer/footer";
import HeaderComponent from "./components/header/header";
import Home from "./components/home/home";
import ProtectedRoute from "./pages/protectedRoutes/protectedRoute";
import TrainerScheduling from "./components/dashboard/trainerScheduling/trainerScheduling";
import CheckTrainerScheduling from "./components/dashboard/checkTrainerScheduling/checkTrainerScheduling";
import TrainerSchedules from "./components/dashboard/trainerSchedules/trainerSchedules";
import TraineeSchedules from "./components/dashboard/traineeSchedules/traineeSchedules";
import NotFound from "./components/notFound/notFound";
import "./App.css";
import "./App.scss";
import TrainerProfile from "./components/dashboard/trainerProfile/trainerProfile";
import TraineeProfile from "./components/dashboard/traineeProfile/traineeProfile";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import AVHeaderComponent from "./components/header/HeaderComponent";
import AVFooterComponent from "./components/footer/AVFooterComponent";
import AVBodyComponent from "./components/home/AVBodyComponent";
import TraineeBookedSchedules from "./components/dashboard/traineeBookedSchedules/traineeBookedSchedules";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Schedules from "./components/dashboard/Schedules/schedule";
import DashBoard from "./components/dashboard/TrainerDashBoard";
import ManagePassword from "./components/dashboard/trainerProfile/managePassword";
import { Layout } from "antd";
import Sidenav from "./components/sidenav/sidenav";
import { useEffect, useState } from "react";

const { Content, Footer } = Layout;
function App() {
  const [isLoggedin, setIsLoggedin] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
 
  const [showResetPwd, setShowResetPwd] = useState(false);
  useEffect(() => {

    if( window.location.href!=undefined && window.location.href.indexOf('resetpassword')!=-1)
    {
      setShowResetPwd(true); 
    console.log('setShowSignup');
    }
    
    const token = localStorage.getItem("userToken");
    if (token) {
      setIsLoggedin(true);
    } else {
      setIsLoggedin(false);
    }
  }, []);
  // console.log(isLoggedin);
  return (
    <>
      {/* {<AVHeaderComponent />} */}

      <div className="App">
        <div className="auth-wrapper">
          <div className="auth-inner">
            <BrowserRouter basename="/">
              <Layout className="main-layout">
                <AVHeaderComponent setIsLoggedin={setIsLoggedin} setShowResetPwd={setShowResetPwd}  showResetPwd={showResetPwd}/>
                {isLoggedin ? (
                  <Content className="content-layout">
                    <Layout>
                      <Sidenav setIsLoggedin={setIsLoggedin}/>
                      <Content>
                        <Routes>
                          {/* {<Route path='/home' element={<Home />} />} */}
                          <Route path="/home" element={<AVBodyComponent />} />
                          {/* <Route exact path="/login" element={<Login />} />
<Route exact path="/register" element={<Signup />} /> */}
                          <Route path="/" element={<ProtectedRoute />}>
                            <Route path="/dashboard" element={<DashBoard />} />
                          </Route>
                          <Route
                            exact
                            path="/trainer-scheduling"
                            element={<TrainerScheduling />}
                          />
                          <Route
                            exact
                            path="/trainer-schedules"
                            element={<Schedules />}
                          />
                                <Route
                            exact
                            path="/trainer-schedulesing"
                            element={<TrainerSchedules />}
                          />
                          <Route
                            exact
                            path="/profile"
                            element={<TrainerProfile />}
                          />
                          <Route
                            exact
                            path="/manage-password"
                            element={<ManagePassword />}
                          />
                          <Route
                            exact
                            path="/check-trainer-schedule"
                            element={<CheckTrainerScheduling />}
                          />
                          <Route
                            exact
                            path="/trainee-schedules"
                            element={<TraineeSchedules />}
                          />
                          <Route
                            exact
                            path="/trainee-bookded-schedules"
                            element={<TraineeBookedSchedules />}
                          />
                          {/* <Route exact path='/trainer-profile' element={<TraineeProfile />} /> */}
                          <Route exact path="*" element={<NotFound />} />
                        </Routes>
                        {/* <AVFooterComponent /> */}
                      </Content>
                    </Layout>
                  </Content>
                ) : (
                  <>
                    <Routes>
                      {/* {<Route path='/home' element={<Home />} />} */}
                      <Route path="/home" element={<Home />} />
                      <Route path="/resetpassword" element={<AVBodyComponent />} />
                      {/* <Route exact path="/login" element={<Login />} />
<Route exact path="/register" element={<Signup />} /> */}
                    </Routes>
                    <FooterComponent />
                  </>
                )}
              </Layout>
            </BrowserRouter>
          </div>
          {/*<FooterComponent />*/}
          {}
        </div>
      </div>
      <ToastContainer limit={1} />
    </>
  );
}

export default App;
