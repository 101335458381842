import React, { Component, useContext, useState } from "react";

import OpenModal from "../modal/modal"; 
import Scheduler, {
  SchedulerData,
  ViewTypes,
  DemoData
} from "react-big-scheduler";
import "react-big-scheduler/lib/css/antd-globals-hiding-hack.css"
import config from "react-big-scheduler/lib/config";
import { connect } from 'react-redux';
import withDragDropContext from "./withDe";
import "react-big-scheduler/lib/css/style.css"; 
import "./style.css";
import { DATE_FORMAT } from "react-big-scheduler";
import { useDispatch } from "react-redux";
import { userSelector, getUser, clearState, getSchedulers, getScheduler, getAllTrainerNames, getSchedulerBySearch, getSchedulerDetailsById, bookTraineeSlot } from "../../../pages/features/userSlice";
import { Context } from "../../../pages/features/storeContext";
import { SuccessScheduleTrainerNotification } from "../../../pages/notifications/notification";
import { confirm } from "react-confirm-box";
import moment from 'moment';
import { format } from 'date-fns'
let resources = [ ];
let dispatch=useDispatch;
class Basic extends Component {
  
  constructor(props) {
    super(props);
   
    const { dispatch } = this.props;  
    // const dispatcha = useDispatch();   
    //let schedulerData = new SchedulerData(new moment("2017-12-18").format(DATE_FORMAT), ViewTypes.Week);
   // let schedulerData = new SchedulerData(new Date(), ViewTypes.Week);
    let schedulerData = new SchedulerData(new Date(), 
    ViewTypes.Week,false,null,{displayWeekend:false,   dayStartFrom: 8,
      dayStopTo: 18,    nonWorkingTimeHeadBgColor: '#00000',nonWorkingTimeHeadColor: '#00000',
          nonAgendaSlotMinHeight: 168, weekCellWidth:156,weekResourceTableWidth:156,
		agendaResourceTableWidth: 500, minuteStep:60,weekMaxEvents:8,
      dayCellWidth: 140,
      nonWorkingTimeBodyBgColor: '#00000', resourceName:'Available Trainers',      eventItemPopoverDateFormat: ' MMM D, YYYY',
      views: [{ viewName: 'Day', viewType: ViewTypes.Day, showAgenda: false, isEventPerspective: false }, { viewName: 'Week', viewType: ViewTypes.Week, showAgenda: false, isEventPerspective: false }
      ]});
   
    let arr=[];
    schedulerData.localeMoment.locale("en-US");
    if(props!=undefined && props.data!=undefined && props.data.length>0)
    {
      console.log('----------------')
      console.log(props.data);
      console.log(props.parentToChild);
      console.log('----------------')
      this.setState({schedulerData:{resources:props.data}});
      this.setState({schedulerData:{events:props.parentToChild}});
      this.setState({eventsdata:props.parentToChild});
      schedulerData.setResources(props.data);
      // schedulerData.setResources('hiiiii');
      schedulerData.setEvents(props.parentToChild);
    }
    else 
    {
      console.log('----------- -----')
      console.log(props.parentToChild);
      console.log('----------------') 
    }
    console.log(DemoData.resources)
    console.log(DemoData.events)
   // schedulerData.setEvents(props.parentToChild);
    this.state = {
      viewModel: schedulerData,isModalOpen:true,modal:false,xyz:'asd',schedulerData:{},
      eventsdata:[],
      optionsWithLabelChange: {
        closeOnOverlayClick: false,
        labels: {
          confirmable: "Confirm",
          cancellable: "Cancel"
        }
      }
      
    };
    this.setState({eventsdata:props.parentToChild});
    this.setState({modal:false,isModalOpen:false});
    resources=props.parentToChild;
    console.log(props.parentToChild);

    // bookTrainee(details){
    //   dispatcha(bookTraineeSlot(details))
    // }
    // bookTrainee = async ()=>
    // {
    //   console.log('Inside demo function')
    // }
  }

  render() {
    const { viewModel } = this.state;
    return ( 
        <div style={{overflowX:"scroll"}}>
         
          <Scheduler newConfig={config}
            schedulerData={viewModel}
            prevClick={this.prevClick}
            nextClick={this.nextClick }
     //       onSelectDate={this.onSelectDate}
          onViewChange={this.onViewChange}
    //        eventItemClick={this.eventClicked}
        //    viewEventClick={this.ops1}
            summaryPos='TopLeft'
        //    viewEventText="More Info"
            viewEvent2Text="Book"
            viewEvent2Click={this.ops2}
      /*      updateEventStart={this.updateEventStart}
            updateEventEnd={this.updateEventEnd}
            moveEvent={this.moveEvent}
            newEvent={this.newEvent}
            onScrollLeft={this.onScrollLeft}
            onScrollRight={this.onScrollRight}
            onScrollTop={this.onScrollTop}
            onScrollBottom={this.onScrollBottom}
            toggleExpandFunc={this.toggleExpandFunc}*/
          />
        </div>
         
    );
  }

  prevClick = schedulerData => {
    schedulerData.prev();
    schedulerData.setEvents(resources);
    this.setState({
      viewModel: schedulerData
    });
  };
    bookSlot = (id, userId, start_time, end_time) => {
    console.log('h', id, userId);
    const details = { "id": id, "start_time": start_time, "end_time": end_time, "trainer_user_id": userId };
    dispatch(bookTraineeSlot(details));
     console.log(details);

}
// bookSlot1 = (event) => {
//   console.log('h', event);
  // const details = { "id": event.id, "start_time": event.start, "end_time": event.end, "trainer_user_id": userId };
  //  dispatchEvent(bookTraineeSlot('book',event));
  //  console.log(details);
// }

  nextClick = schedulerData => {
    console.log('next');
    console.log(this.state.eventsdata);
    console.log(resources);
     console.log(schedulerData);
     schedulerData.next();
    schedulerData.setEvents(resources);
    console.log(this.state.viewModel);
    this.setState({
      viewModel: schedulerData
    }); 
  };

  onViewChange = (schedulerData, view) => {

    schedulerData.setViewType(
      view.viewType,
      view.showAgenda,
      view.isEventPerspective
    );
    schedulerData.setEvents(resources);
    this.setState({
      viewModel: schedulerData
    });
  };

  onSelectDate = (schedulerData, date) => {
    schedulerData.setDate(date);
    schedulerData.setEvents(resources);
    this.setState({
      viewModel: schedulerData
    });
  };

  eventClicked = (schedulerData, event) => {
    alert(
      `You just clicked an event: {id: ${event.id}, title: ${event.title}}`
    );
  };

  ops1 = (schedulerData, event) => {
    console.log(event);
    console.log(this.state.isModalOpen);
   
    console.log(this.state.modal);
    this.isModalOpen=true;
    this.setState({modal:true});
    console.log(this.state.modal); 
   // const details = { "id": id, "start_time": start_time, "end_time": end_time, "trainer_user_id": userId };

    this.bookSlot(event.id, event.user_id, event.start_time, event.start_time) ;
    alert(
      `You just executed ops1 to event: {id: ${event.id}, title: ${
        event.title
      }}`
    );
  };
   
 
  ops2 = async (schedulerData, event) => {
    console.log(event);
    // setEvent(event);
    // bookTraineeSlot(event);
    // this.bookSlot1(event);
     console.log(new Date());//2023-01-06 10:00:00
   const ss=  format(new Date(), 'MM/dd/yyyy');
   
   console.log(ss);
   //console.log(format(event.start, 'MM/dd/yyyy'));
  const date1=  new moment(event.start,"YYYY-MM-DD hh:mm:ss");
  const date2=  new moment(new Date());
  //date1.setHour(event.start) at 10:00am on Tuesday, January 10th
  // dddd, MMMM Do
  console.log(date1);
  console.log(date1.format('hh:mma')); 

  console.log(date2);
  console.log(date1.format('MMM DDD YYYY')); 
  //.format('MMMM Do YYYY, h:mm:ss a');
    const result =  await confirm(`You are booking a lesson with  ${event.resourceNameCustom} at ${date1.format('h:mma')} on  ${date1.format('dddd, MMMM Do')} .`,
     this.state.optionsWithLabelChange);
    console.log(result);
    if (result) {
      this.props.bookTraineeSlot(event); 
      
      console.log("You click yes!");
      return;
    }
    console.log("You click No!");
    
  };

  newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
   
    if (
      confirm(
        `Do you want to create a new event? {slotId: ${slotId}, slotName: ${slotName}, start: ${start}, end: ${end}, type: ${type}, item: ${item}}`
      )
    ) {
      let newFreshId = 0;
      schedulerData.events.forEach(item => {
        if (item.id >= newFreshId) newFreshId = item.id + 1;
      });
      console.log('start '+start);
      console.log('end '+end);
      console.log('slotId '+slotId);
      let newEvent = {
        id: newFreshId,
        title: "New event you just created",
        start: start,
        end: end,
        resourceId: slotId,
        bgColor: "purple"
      };
      schedulerData.addEvent(newEvent);
      this.setState({
        viewModel: schedulerData
      });
    }
  };

  updateEventStart = (schedulerData, event, newStart) => {
    if (
      confirm(
        `Do you want to adjust the start of the event? {eventId: ${
          event.id
        }, eventTitle: ${event.title}, newStart: ${newStart}}`
      )
    ) {
      schedulerData.updateEventStart(event, newStart);
    }
    this.setState({
      viewModel: schedulerData
    });
  };

  updateEventEnd = (schedulerData, event, newEnd) => {
    if (
      confirm(
        `Do you want to adjust the end of the event? {eventId: ${
          event.id
        }, eventTitle: ${event.title}, newEnd: ${newEnd}}`
      )
    ) {
      schedulerData.updateEventEnd(event, newEnd);
    }
    this.setState({
      viewModel: schedulerData
    });
  };

  moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
    if (
      confirm(
        `Do you want to move the event? {eventId: ${event.id}, eventTitle: ${
          event.title
        }, newSlotId: ${slotId}, newSlotName: ${slotName}, newStart: ${start}, newEnd: ${end}`
      )
    ) {
      schedulerData.moveEvent(event, slotId, slotName, start, end);
      this.setState({
        viewModel: schedulerData
      });
    }
  };

  onScrollRight = (schedulerData, schedulerContent, maxScrollLeft) => {
    if (schedulerData.ViewTypes === ViewTypes.Day) {
      schedulerData.next();
      schedulerData.setEvents(resources);
      this.setState({
        viewModel: schedulerData
      });

      schedulerContent.scrollLeft = maxScrollLeft - 10;
    }
  };

  onScrollLeft = (schedulerData, schedulerContent, maxScrollLeft) => {
    if (schedulerData.ViewTypes === ViewTypes.Day) {
      schedulerData.prev();
      schedulerData.setEvents(resources);
      this.setState({
        viewModel: schedulerData
      });

      schedulerContent.scrollLeft = 10;
    }
  };

  onScrollTop = (schedulerData, schedulerContent, maxScrollTop) => {
    console.log("onScrollTop");
  };

  onScrollBottom = (schedulerData, schedulerContent, maxScrollTop) => {
    console.log("onScrollBottom");
  };

  toggleExpandFunc = (schedulerData, slotId) => {
    schedulerData.toggleExpandStatus(slotId);
    this.setState({
      viewModel: schedulerData
    });
  };
}

const mapDispatchToProps = (dispatch,event) => {
  return {
    bookTraineeSlot: (event) => dispatch(bookTraineeSlot(event))
  }
};

export default connect(null, mapDispatchToProps)(withDragDropContext(Basic))

// export default;